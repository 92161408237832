// PACKAGES
import 'core-js/stable'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
// UTILS
import './util/i18n'
// STYLES
import './index.css'
import '@fontsource/roboto'
import '@fontsource/roboto/500.css'
// COMPONENTS
import App from './App'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
