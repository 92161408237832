// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
// UI
import {
  AppBar,
  Grid,
  Typography,
  Toolbar,
  useMediaQuery
} from '@material-ui/core'
import { StylesProvider } from '@material-ui/core/styles'
// CONFIG
import { imagesURL } from '../config'
// UTILS
import device from '../util/device'
// COMPONENTS
import LanguageSelectDark from '../components/Layout/LanguageSelectDark'
const FlexContainer = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: [header] min-content [content] auto [footer] min-content [end];
`
const TrToolbar = styled(Toolbar)`
  grid-row-start: header;
   {
    background-color: #f4f4f4;
    .logo-icon {
      width: 80px;
    }
  }
`
const TrContentArea = styled.div`
  grid-row-start: content;
  flex: 1 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
`
const FooterArea = styled.div`
  box-sizing: border-box;
  display: flex;
  grid-row-start: footer;
  justify-content: flex-start;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100vw;
  @media ${device.tablets} {
    padding-left: 20px;
  }
`
const FooterContainer = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 50px;
`
const FooterGrid = styled(Grid)`
  max-width: fit-content;
  padding-left:10px;
  display:flex;
  flex-wrap:wrap;
  align-content: center;
`
const FooterLabel = styled(Typography)`
  font-size: small;
`
const CopyrightLink = styled.a`
  color: #000000;
  font-size: small;
  text-decoration: none;
`
const SplashLayout = ({ children }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(device.tablets)
  // TODO material-ui menu components give warnings with Strict Mode
  // Upgrade to material-ui v5 once it is released
  return (
    <StylesProvider injectFirst>
      <FlexContainer>
        <AppBar position='static' elevation={0}>
          <TrToolbar variant='dense'>
            <Grid container spacing={4} justifyContent='flex-end' alignItems='center'>
              <Grid item>
                <img
                  className='logo-icon'
                  alt='talentReef'
                  src={imagesURL + '/logo/powered-by/gray-color-462x222.png'}
                />
              </Grid>
            </Grid>
          </TrToolbar>
        </AppBar>
        <TrContentArea>{children}</TrContentArea>
        <FooterArea>
          <Grid container justifyContent='space-between'>
            <FooterContainer item xs={12} sm={4} style={{ minWidth: !isMobile ? 'fit-content' : null }}>
              <FooterGrid>
                <LanguageSelectDark />
              </FooterGrid>
              <FooterGrid>
                <Typography>
                  <CopyrightLink href='https://www.talentreef.com/privacy-policy/' target='_blank' rel='noopener noreferrer'>{t('footer-privacy')}</CopyrightLink>
                </Typography>
              </FooterGrid>
              <FooterGrid>
                <Typography style={{ fontSize: 'small', paddingTop: '2px' }}>
                  <CopyrightLink href='https://mitratech.my.site.com/talentreef/s/article/Application-Access-Email-Delivery-Troubleshooting-Steps' target='_blank'>{t('need-help')}</CopyrightLink>
                </Typography>
              </FooterGrid>
            </FooterContainer>
            <FooterContainer item xs={12} sm={4}>
              <FooterGrid>
                <FooterLabel align='right'>
                  {t('footer-recovery-copyright', { year1: new Date().getFullYear() - 1, year2: new Date().getFullYear() })}
                </FooterLabel>
              </FooterGrid>
            </FooterContainer>
          </Grid>
        </FooterArea>
      </FlexContainer>
    </StylesProvider>
  )
}
SplashLayout.propTypes = {
  children: PropTypes.any
}
export default SplashLayout
