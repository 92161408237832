// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// UI
import { FormControl, FormLabel } from '@material-ui/core'

const HeadingLabel = styled(FormLabel)`
    font-size: 1.25rem;
    font-weight: 500;
    color: rgb(0,0,0);
    margin: 0;
`

const SubheadingLabel = styled(FormLabel)`
    font-size: 0.8rem;
    color: rgb(60,60,60);
    margin: 0;
`

const BodyLabel = styled(FormLabel)`
    font-size: 0.6rem;
    color: rgb(0,0,0);
    margin: 0;
`

const FormatToComponent = {
  heading: HeadingLabel,
  subheading: SubheadingLabel,
  body: BodyLabel
}

export default function InformationQuestion ({
  question: {
    text,
    format
  }
}) {
  const LabelComponent = FormatToComponent[format] || HeadingLabel
  return (
    <FormControl component='fieldset' margin='dense'>
      <LabelComponent>
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </LabelComponent>
    </FormControl>
  )
}

InformationQuestion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired
  }).isRequired
}
