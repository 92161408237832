import http from './httpClient'
import { bffURL } from '../config'

const branding = {
  get: (postingId, clientId, brandId) => {
    return http.get(
      `${bffURL}/postings/${postingId}/branding/${clientId}/${brandId}`
    ).catch(e => (console.warn(`Failed to fetch branding information: ${e}`)))
  }
}

export default branding
