import http from './httpClient'
import { bffURL } from '../config'

export default class Applications {
  static getApplicationsWithToken (token, idToken) {
    return http.get(
      `${bffURL}/profiles/token/${token}/applications/active`, idToken
    )
  }

  static getApplicationWithToken (token, idToken) {
    return http.get(
      `${bffURL}/applications/${token}`, idToken
    )
  }

  static getApplicationsWithProfileId (idToken) {
    return http.get(
      `${bffURL}/auth/profiles/applications/active`, idToken
    )
  }

  static requestApplicationRecovery (contact, contactType, idToken) {
    return http.post(
      `${bffURL}/profiles/applicants/send-recovery-notification?${contactType}=${encodeURIComponent(contact)}`, idToken
    )
  }
}
