// PACKAGES
import React from 'react'
import styled from 'styled-components'
// UI
import { FormLabel } from '@material-ui/core'

const QuestionFormLabel = styled(FormLabel)`
  color: rgb(0,0,0);
  margin: 1em 0;
`
const QuestionFormLabelWrapper = props => {
  return <QuestionFormLabel {...props} component='legend' />
}

export default QuestionFormLabelWrapper
