// PACKAGES
import styled from 'styled-components'
// UI
import { Typography } from '@material-ui/core'

const PageHeaderTypography = styled(Typography)`
  margin: 15px 0;
`

export default PageHeaderTypography
