import { CircularProgress, Grid, Typography, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const IFrameContainer = styled(Grid)(({ theme }) => ({
  borderRadius: '0.25rem',
  width: '100%',
  height: '44.0625rem',
  border: '1px solid #E0E0E0',
  marginBottom: '1.81rem',

  [theme.breakpoints.down('xs')]: {
    height: '37.9375rem',
    marginBottom: '1.5rem',
    marginTop: '0.87rem'
  }
}))

const WotcMessage = styled(Typography)(() => ({
  fontFamily: 'Inter',
  lineHeight: '1.5rem'
}))

const WotcAssessmentProgress = () => {
  const { t } = useTranslation()

  return (
    <IFrameContainer container justifyContent='center' alignContent='center'>
      <Grid item xs={12} style={{ display: 'inline-flex', marginBottom: '0.75rem', justifyContent: 'center' }}>
        <CircularProgress style={{ width: '2.5rem', height: '2.5rem', color: '#0D1D38' }} />
      </Grid>
      <Grid item>
        <WotcMessage variant='subtitle1' style={{ textAlign: 'center' }}>
          {t('wotc-loading-message-1')}<br />
          {t('wotc-loading-message-2')}
        </WotcMessage>
      </Grid>
    </IFrameContainer>
  )
}

export default WotcAssessmentProgress
