// PACKAGES
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useContextSelector } from 'use-context-selector'
// UI
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText
} from '@material-ui/core'
// CONTEXTS
import { AnswerContext } from '../../contexts/AnswerContext'

export default function HierarchicalSecondaryQuestion ({
  question,
  parentId,
  errorMessage,
  onChange,
  value,
  ...args
}) {
  const primaryAnswer = useContextSelector(AnswerContext, state => state[0][parentId])
  const [cachedPrimaryAnswer, setCachedPrimaryAnswer] = useState(primaryAnswer || { value: '' })
  const [secondaryAnswer, setSecondaryAnswer] = useState('')
  const isError = !!errorMessage

  let optionsForSelectedPrimary
  if (primaryAnswer) {
    const selectedPrimaryOption = primaryAnswer.value
    optionsForSelectedPrimary = question.hierarchicalOptions.find(
      opt => opt.condition.value === selectedPrimaryOption
    )
  }

  useEffect(() => {
    const parentSelectionHasChanged = !!(
      primaryAnswer?.value &&
      cachedPrimaryAnswer.value && // when autocompleting from a blank state, do not run logic to clear input when parent changes
      (primaryAnswer.value !== cachedPrimaryAnswer.value)
    )

    const clearSecondaryAnswer = () => {
      setCachedPrimaryAnswer(primaryAnswer)
      setSecondaryAnswer('')
      onChange({ target: { value: '' } })
    }

    parentSelectionHasChanged && clearSecondaryAnswer()
  }, [cachedPrimaryAnswer, setCachedPrimaryAnswer, primaryAnswer, onChange])

  // necessary for autocomplete functionality
  useEffect(() => { setSecondaryAnswer(value) }, [value])

  return (
    <div>
      {// eslint-disable-next-line
        (primaryAnswer?.value === undefined || primaryAnswer?.value === '' || optionsForSelectedPrimary) ? ( // showing secondary question at first if primary was not answered at all but hiding it in case we chose an option on the primary that does not match the secondary conditions
          <FormControl
            margin='normal'
            variant='outlined'
            key={question.id}
            error={isError}
            size='small'
            fullWidth
            required={question.required}
          >
            <InputLabel size='small' id={question.id + '-label'} data-testid={question.id + '-label'}>
              {question.question}
            </InputLabel>

            <Select
              {...args}
              labelId={question.id + '-label'}
              id={question.id + '-main'}
              name={question.id + '-main'}
              label={question.question}
              value={secondaryAnswer}
              onChange={(e) => {
                setSecondaryAnswer(e.target.value)
                onChange(e)
              }}
            >
              {// eslint-disable-next-line
                optionsForSelectedPrimary ? (
                  optionsForSelectedPrimary.options.map(opt => (
                    <MenuItem
                      data-testid='select-option'
                      key={opt.value}
                      value={opt.value}
                    >
                      {opt.label}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem data-testid='select-option' key='default' value=''>
                    {question.defaultOption}
                  </MenuItem>
                )
              }
            </Select>
          </FormControl>
        ) : (
          null
        )
      }
      {isError && <FormHelperText error>{errorMessage}</FormHelperText>}
    </div>
  )
}

HierarchicalSecondaryQuestion.propTypes = {
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  parentId: PropTypes.any,
  question: PropTypes.shape({
    defaultOption: PropTypes.any,
    hierarchicalOptions: PropTypes.arrayOf(
      PropTypes.shape({
        condition: PropTypes.shape({
          value: PropTypes.any
        })
      })
    ),
    id: PropTypes.string,
    question: PropTypes.any,
    required: PropTypes.any
  })
}
