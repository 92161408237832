// PACKAGES
import { useEffect } from 'react'
import { useContext } from 'use-context-selector'
import { useLocation } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import queryString from 'query-string'
// CONTEXTS
import { AppContext } from '../contexts/AppContext'
// CONFIG
import { bffURL } from '../config'

// eslint-disable-next-line
export const useMountEffect = fn => useEffect(fn, [])

export const useQuery = () => new URLSearchParams(useLocation().search)

/**
 * @function useTrackingIntegration
 * @description a hook implementation for dynamically setting tracking script tag src's we retrieve from our backend service
 * consumed upstream by a `PixelTracking` component which can be conditionally rendered
 * @param {string} eventName - name of the event which we want to set tracking for, for example: 'view', 'start', 'finish', etc
 *
 */
export const useTrackingIntegration = (eventName) => {
  const [appContext, appDispatch] = useContext(AppContext)

  const clientEventTrackingUrl = (clientId, queryString) => `${bffURL}/clients/${clientId}/events?${queryString}`

  useEffect(() => {
    (async () => {
      let { trackingParams, trackingUrls } = appContext

      if (trackingParams && !trackingUrls) {
        try {
          const { clientId, jobId: reqId } = appContext.trackingParams
          // generate a random UUID to use for procNum value (only used by some integrations for the 'finish' step)
          const procNum = uuid()
          const qs = queryString.stringify({ procNum, reqId })
          const requestUrl = clientEventTrackingUrl(clientId, qs)
          const trackingResponse = await window.fetch(requestUrl)
          trackingUrls = await trackingResponse.json()
          // add tracking url data to context to prevent refetching later
          appDispatch({ id: 'trackingUrls', value: trackingUrls })
        } catch (e) {
          console.warn(`There was a problem with the tracking integration for event "${eventName}": ${e}`)
        }
      }

      // each event tracking stage comes back in the form of an array of potentially many different scripts
      trackingUrls?.[eventName]?.forEach(eventUrlConfig => {
        if (eventUrlConfig.src && !document.getElementById(eventUrlConfig.src)) {
          let pixel
          if (eventUrlConfig.type?.includes('image')) {
            pixel = document.createElement('img')
            pixel.width = 1
            pixel.height = 1
            pixel.style.cssText = 'display: none;'
          } else {
            pixel = document.createElement('script')
            pixel.async = true
          }
          // so that we can select for element in the hook return callback
          pixel.id = eventUrlConfig.src
          // we can expect keys like 'src' and 'type' from our client events endpoint
          Object.entries(eventUrlConfig).forEach(([key, val]) => {
            pixel[key] = val
          })
          eventUrlConfig.type?.includes('image')
            ? document.body.appendChild(pixel)
            : document.head.appendChild(pixel)
        }
      })
    })()

    return () => {
      appContext.trackingUrls?.[eventName]?.forEach(eventUrlConfig => {
        // remove scripts previously appended via id
        const pixel = document.getElementById(eventUrlConfig.src)
        if (eventUrlConfig.src && pixel) {
          if (pixel.tagName === 'IMG') {
            document.body.removeChild(pixel)
          } else {
            document.head.removeChild(pixel)
          }
        }
      })
    }
  }, [appDispatch, eventName]) // eslint-disable-line
}
