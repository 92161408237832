// PACKAGES
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
// UI
import Chip from '@mui/material/Chip'

const AdditionalStepsChip = styled(Chip)`
  && {
    background-color: #CFEDF1;
  }
`

const IncompleteChip = styled(Chip)`
  && {
    background-color: #91D9E1;
  }
`

const CompleteChip = styled(Chip)`
  && {
    background-color: #59B8C2;
  }
`

const OfferExtendedChip = styled(Chip)`
  && {
    background-color: #3F8289;
  }
`

const PositionTerminatedChip = styled(Chip)`
  && {
    background-color: #525252;
    color: white;
  }
`

const StatusLabel = ({ status }) => {
  const { t } = useTranslation()
  const label = t(status)

  if (status.includes('submitted-incomplete')) {
    return <AdditionalStepsChip label={label} />
  } else if (status.includes('incomplete')) {
    return <IncompleteChip label={label} />
  } else if (status.includes('completed')) {
    return <CompleteChip label={label} />
  } else if (status.includes('offer')) {
    return <OfferExtendedChip label={label} />
  } else if (status.includes('rejected')) {
    return <PositionTerminatedChip label={label} />
  }

  return <Chip label={label} />
}

export default StatusLabel
