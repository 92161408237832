// PACKAGES
import React from 'react'
import styled from 'styled-components'
// UI
import { Box, Typography } from '@material-ui/core'
// CONFIG
import { brandAssetURL } from '../../config'
// UTILS
import device from '../../util/device'

const PostHeaderContainer = styled(Box)`
  width: 100%;
`

const PostingHeader = styled(Box)`  
  align-items: center;
  display: flex;
  height: 110px;
  padding-left: 138px;
  width: calc(100vw - 138px);

  @media ${device.tablets} {
    padding-left: 20px;
    width: calc(100vw - 20px);
  }
`

const PostingInfo = styled(Box)`
  margin-left: ${props => props.branded ? '3vw' : 0};
`

const SubtitleDetails = styled(Typography)`
  font-weight: 500;
`

const PostHeader = ({ postingDetails, branding }) => {
  return (
    <PostHeaderContainer>
      <PostingHeader>
        {branding && branding.logo && postingDetails && (
          <img
            style={{ width: '5rem' }}
            src={`${brandAssetURL}/${postingDetails.clientId}/${branding.logo?.fileId}`}
            alt={branding.logo?.alternateText}
          />
        )}
        {postingDetails && (
          <PostingInfo branded={branding}>
            <Typography variant='h6'>{postingDetails.brand}</Typography>
            <SubtitleDetails>
              {`${postingDetails.title || ''} | ${postingDetails.address?.city || ''}, ${postingDetails.address?.stateOrProvince || ''}`}
            </SubtitleDetails>
          </PostingInfo>
        )}
      </PostingHeader>
    </PostHeaderContainer>
  )
}

export default PostHeader
