// PACKAGES
import React from 'react'
import { Redirect, useParams, useLocation } from 'react-router-dom'
// UTILS
import { CONTACT_INFO } from '../../util/consts'

const ApplicationRedirect = () => {
  const { postingId } = useParams()
  const { search } = useLocation()

  return (
    <Redirect to={{
      search,
      pathname: `/postings/${postingId}/application/${CONTACT_INFO}`
    }}
    />
  )
}

export default ApplicationRedirect
