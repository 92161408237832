// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
// UI
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText
} from '@material-ui/core'

export default function HierarchicalPrimaryQuestion ({
  errorMessage,
  question,
  value,
  ...args
}) {
  const isError = !!errorMessage

  return (
    <div>
      <FormControl
        error={isError}
        fullWidth
        margin='normal'
        size='small'
        variant='outlined'
        required={question.required}
      >
        <InputLabel size='small' id={question.id + '-label'} data-testid={question.id + '-label'}>
          {question.question}
        </InputLabel>

        <Select
          {...args}
          defaultValue=''
          id={question.id + '-main'}
          label={question.question}
          labelId={question.id + '-label'}
          required={question.required}
          size='small'
          value={value}
        >
          {question.options.map(opt => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {isError && <FormHelperText error>{errorMessage}</FormHelperText>}
    </div>
  )
}

HierarchicalPrimaryQuestion.propTypes = {
  errorMessage: PropTypes.string,
  question: PropTypes.shape({
    id: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    ),
    question: PropTypes.string,
    required: PropTypes.bool
  }),
  value: PropTypes.string
}
