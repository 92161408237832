export function getEnv (origin) {
  if (origin.startsWith('http://localhost')) {
    return 'local'
  } else if (origin.startsWith('https://dev-')) {
    return 'dev'
  } else if (origin.startsWith('https://qa-')) {
    return 'qa'
  } else if (origin.startsWith('https://uat-')) {
    return 'uat'
  }
  return 'prod'
}

const envConfig = {
  local: {
    authURL: 'http://localhost:9000',
    applicantURL: 'https://dev-applicant.trdev.co',
    bffURL: 'http://localhost:9000',
    brandAssetURL: 'https://dev-marketing-assets.trdev.co',
    cognitoIdentityPoolId: 'us-west-2:61224fd7-8e09-4f96-8c9b-2bbe434af1fa',
    domain: '.trdev.co',
    imagesURL: 'https://dev-images.trdev.co',
    jobDescriptionURL: 'http://localhost:3001',
    legacyApplyURL: 'https://dev-cf-apply.trdev.co',
    mouseflowApiKey: '5d44fae4-48f5-4d39-bbed-b989e5192dc2',
    pinpointProjectId: '31d680f5dcb3458b9726e524f3e9b853',
    postgridApiKey: 'test_sk_gCJ9CCG5tFKSYmk9UTa86T',
    postgridUrl: 'https://api.postgrid.com/v1/addver/completions',
    recoverPhone: true
  },
  dev: {
    authURL: 'https://dev-auth.trdev.co',
    applicantURL: 'https://dev-applicant.trdev.co',
    bffURL: 'https://dev-applicant-bff-external-api.trdev.co',
    brandAssetURL: 'https://dev-marketing-assets.trdev.co',
    cognitoIdentityPoolId: 'us-west-2:61224fd7-8e09-4f96-8c9b-2bbe434af1fa',
    domain: '.trdev.co',
    imagesURL: 'https://dev-images.trdev.co',
    jobDescriptionURL: 'https://dev-applicant-sr.jobappdemo.com',
    legacyApplyURL: 'https://dev-cf-apply.trdev.co',
    mouseflowApiKey: '5d44fae4-48f5-4d39-bbed-b989e5192dc2',
    pinpointProjectId: '31d680f5dcb3458b9726e524f3e9b853',
    postgridApiKey: 'test_sk_gCJ9CCG5tFKSYmk9UTa86T',
    postgridUrl: 'https://api.postgrid.com/v1/addver/completions',
    recoverPhone: false
  },
  qa: {
    authURL: 'https://qa-auth.jobappdemo.com',
    applicantURL: 'https://qa-applicant.jobappdemo.com',
    bffURL: 'https://qa-applicant-bff-external-api.jobappdemo.com',
    brandAssetURL: 'https://qa-marketing-assets.jobappdemo.com',
    cognitoIdentityPoolId: 'us-west-2:bf17953e-46f8-4942-8715-e45c52119938',
    domain: '.jobappdemo.com',
    imagesURL: 'https://qa-images.jobappdemo.com',
    jobDescriptionURL: 'https://qa-applicant-sr.jobappdemo.com',
    legacyApplyURL: 'https://qa-cf-apply.jobappdemo.com',
    mouseflowApiKey: '72919247-93e9-4621-9466-9dfc45280de3',
    pinpointProjectId: 'a69a66f319524838ab017983f5e769d2',
    postgridApiKey: 'live_pk_wVEDU3EHV5FDvqf9KB5mG8',
    postgridUrl: 'https://api.postgrid.com/v1/addver/completions',
    recoverPhone: false
  },
  uat: {
    authURL: 'https://uat-auth.jobappdemo.com',
    applicantURL: 'https://uat-applicant.jobappdemo.com',
    bffURL: 'https://uat-applicant-bff-external-api.jobappdemo.com',
    brandAssetURL: 'https://uat-marketing-assets.jobappdemo.com',
    cognitoIdentityPoolId: 'us-west-2:d1e3203d-f725-4017-829b-6bd13e46cc9e',
    domain: '.jobappdemo.com',
    imagesURL: 'https://uat-images.jobappdemo.com',
    jobDescriptionURL: 'https://uat-applicant-sr.jobappdemo.com',
    legacyApplyURL: 'https://uat-cf-apply.jobappdemo.com',
    mouseflowApiKey: 'f5f46a15-d250-45d2-b639-106b3b192aa5',
    pinpointProjectId: 'a56a06a12d4a43ddba9b419236d06e10',
    postgridApiKey: 'live_pk_wVEDU3EHV5FDvqf9KB5mG8',
    postgridUrl: 'https://api.postgrid.com/v1/addver/completions',
    recoverPhone: false
  },
  prod: {
    authURL: 'https://auth.jobappnetwork.com',
    applicantURL: 'https://applicant.jobappnetwork.com',
    bffURL: 'https://prod-applicant-bff-external-api.jobappnetwork.com',
    brandAssetURL: 'https://marketing-assets.jobappnetwork.com',
    cognitoIdentityPoolId: 'us-west-2:4d22fe4c-764c-429a-ac11-dbdd15797229',
    domain: '.jobappnetwork.com',
    imagesURL: 'https://images.jobappnetwork.com',
    jobDescriptionURL: 'https://apply.jobappnetwork.com',
    legacyApplyURL: 'https://cf-apply.jobappnetwork.com',
    mouseflowApiKey: 'fcf890cc-4191-46e0-9bb1-fa3554f143c7',
    pinpointProjectId: 'f4960c39868c49c3951d5d30d56f6e39',
    postgridApiKey: 'live_pk_dZ2KY4ouiESMeRk7VDzhSr',
    postgridUrl: 'https://api.postgrid.com/v1/addver/completions',
    recoverPhone: false
  }
}

export const environment = getEnv(window?.location.origin)
export const {
  authURL,
  applicantURL,
  bffURL,
  brandAssetURL,
  cognitoIdentityPoolId,
  domain,
  imagesURL,
  jobDescriptionURL,
  legacyApplyURL,
  mouseflowApiKey,
  pinpointProjectId,
  postgridApiKey,
  postgridUrl,
  recoverPhone
} = envConfig[environment]
