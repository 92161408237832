// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
// UI
import { Typography } from '@material-ui/core'
// COMPONENTS
import HierarchicalAnswer from './HierarchicalAnswer'
import MultiSelectAnswer from './MultiSelectAnswer'
import PreferenceAnswer from './PreferenceAnswer'
import SelectAnswer from './SelectAnswer'
import UnitAnswer from './UnitAnswer'

const AnswerBox = styled.div`
  margin-bottom: 30px;
`

const AnswerLabel = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`

const QuestionTypography = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`

const AnswerComponent = ({ question, answer }) => {
  const { t } = useTranslation()

  const answerValue = answer ? answer.value : '--'

  switch (question.type) {
    case 'date':
    case 'hierarchical-primary':
    case 'hierarchical-secondary':
    case 'text':
    case 'textarea':
      return <AnswerLabel>{question.validation === 'ssn' ? '*****' + answerValue.substring(5) : answerValue}</AnswerLabel>
    case 'yes-no':
      return <AnswerLabel>{answerValue === '1' ? t('yes') : t('no')}</AnswerLabel>
    case 'multiselect' :
      return <MultiSelectAnswer question={question} answer={answer} />
    case 'hierarchical':
      return <HierarchicalAnswer question={question} answer={answerValue} />
    case 'dropdown':
    case 'select':
      return <SelectAnswer question={question} answer={answerValue} />
    case 'unit':
      return <UnitAnswer question={question} answer={answer} />
    case 'preference':
      return <PreferenceAnswer question={question} answer={answer} />
    default:
      return null
  }
}

const Answer = props => {
  const { question: { question, required } } = props

  return (
    <AnswerBox>
      <QuestionTypography>
        <span dangerouslySetInnerHTML={{ __html: required ? `${question} *` : question }} />
      </QuestionTypography>
      <AnswerComponent {...props} />
    </AnswerBox>
  )
}

Answer.propTypes = {
  answer: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  }),
  question: PropTypes.shape({
    question: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired
}

export default Answer
