import { Box, Grid, Typography, styled } from '@material-ui/core'
import { HelpOutlineOutlined } from '@material-ui/icons'
import { Alert } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AdditionalStepsService from '../../services/additionalSteps'
import queryString from 'query-string'
import WotcAssessmentProgress from './WotcAssessmentProgress'
import WotcAssessmentError from './WotcAssessmentError'
import { APPLY } from '../../util/consts'
import { useParams } from 'react-router-dom'

const WotcTitleBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: '0.5rem',

  [theme.breakpoints.down('xs')]: {
    marginBottom: '0rem'
  }
}))

const WotcTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: '600',
  lineHeight: '1.5rem',

  [theme.breakpoints.down('xs')]: {
    fontSize: '1.125rem',
    fontWeight: '500',
    display: 'inline'
  }
}))

const WotcDescription = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  lineHeight: '1.5rem',
  marginBottom: '1.5rem',

  [theme.breakpoints.down('xs')]: {
    display: 'none'
  }
}))

const WotcAlertContainer = styled(Box)({
  marginTop: '0.75rem'
})

const WotcAlert = styled(Alert)(() => ({
  fontFamily: 'Inter !important',
  lineHeight: '1.25rem !important',
  letterSpacing: '0.01063rem !important',
  color: '#2A5DB7 !important',
  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #2A5DB7 !important'
}))

const HelpIcon = styled(HelpOutlineOutlined)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('xs')]: {
    display: 'inline',
    paddingLeft: '0.75rem'
  }
}))

const IFrame = styled('iframe')(({ theme }) => ({
  borderRadius: '0.25rem',
  width: '100%',
  height: '44.0625rem',
  border: '1px solid var(--mui-gray-300, #E0E0E0)',
  marginBottom: '1.81rem',

  [theme.breakpoints.down('xs')]: {
    height: '37.9375rem',
    marginBottom: '1.5rem',
    marginTop: '0.87rem'
  }
}))

const WotcAssessment = (props) => {
  const [isAlertActive, setIsAlertActive] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [assessmentUrl, setAssessmentUrl] = useState('')
  const { t } = useTranslation()
  const { postingId } = useParams()

  useEffect(() => {
    (async () => {
      const token = queryString.parse(window.location.search).token

      try {
        if (token) {
          const resp = await AdditionalStepsService.getWotcWithToken(postingId, token)
          setAssessmentUrl(resp.questionnaireUrl)
        }
        setIsLoading(false)
      } catch (error) {
        setHasError(true)
        setIsLoading(false)
        props.onError()
      }
    })()
  }, [props, postingId])

  const toggleAlert = () => {
    setIsAlertActive(!isAlertActive)
  }

  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item lg={8} xs={10}>
          <WotcTitleBox>
            <WotcTitle style={props.workflowName === APPLY ? { marginTop: '0rem' } : { marginTop: '2rem' }} variant='h6'> {t('wotc-title')} </WotcTitle><HelpIcon data-testid='help-wotc' aria-label='help-wotc' onClick={() => toggleAlert()} />
          </WotcTitleBox>
          <WotcDescription variant='subtitle1'>{t('wotc-frame-content')}</WotcDescription>
          <WotcAlertContainer style={{ display: isAlertActive ? 'none' : '' }}>
            <WotcAlert data-testid='help-alert' severity='info' onClose={() => toggleAlert()}>{t('wotc-frame-content')}</WotcAlert>
          </WotcAlertContainer>
        </Grid>
        <Grid item xs={10}>
          {
            isLoading
              ? <WotcAssessmentProgress />
              : hasError
                ? <WotcAssessmentError timeRemaining={5} />
                : <IFrame id='wotc-redirect' onLoad={props.onLoad} src={assessmentUrl} title='wotc provider embed' />
          }
        </Grid>
      </Grid>
    </>
  )
}

export default WotcAssessment
