// PACKAGES
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
// UI
import { Box, Typography } from '@material-ui/core'
// CONFIG

// UTILS
import device from '../../util/device'

const PostHeaderContainer = styled(Box)`
  width: 100%;
`

const PostingHeader = styled(Box)`  
  align-items: center;
  display: flex;
  height: 110px;
  padding-left: 80px;
  width: calc(100vw - 138px);

  @media ${device.tablets} {
    padding-left: 20px;
    width: calc(100vw - 20px);
  }
`

const PostingInfo = styled(Box)`
  margin-left: 0;
`

const SubtitleDetails = styled(Typography)`
  font-size: 1rem;
  font-weight: 500;
  color: #0D1D38;
`

const HeaderDetails = styled(Typography)`
  font-size: 1.5rem;
`

const ApplicationSelectHeader = ({ name }) => {
  const { t } = useTranslation()

  return (
    <>
      <PostHeaderContainer>
        <PostingHeader>
          <PostingInfo>
            <HeaderDetails variant='h6'>{t('applications')}</HeaderDetails>
            <SubtitleDetails>
              {name}
            </SubtitleDetails>
          </PostingInfo>
        </PostingHeader>
      </PostHeaderContainer>
    </>
  )
}

export default ApplicationSelectHeader
