// PACKAGES
import React, { useEffect, useState } from 'react'
// SERVICES
import Redirect from '../../services/redirect'
// COMPONENTS
import Message from './Message'

const RedirectMessage = () => {
  const [url, setUrl] = useState('')

  useEffect(() => {
    const url = Redirect.parseUrl()
    if (url) {
      setUrl(url)
      Redirect.navigate(url)
    } else {
      setUrl('invalid redirect url!')
    }
  }, [setUrl])

  return <Message>Redirecting to {url}</Message>
}

export default RedirectMessage
