// PACKAGES
import React from 'react'
// CONTEXTS
import { AnswerProvider } from './contexts/AnswerContext'
import { FileProvider } from './contexts/FileContext'
import { SectionProvider } from './contexts/SectionContext'
import { StepsProvider } from './contexts/StepsContext'
// COMPONENTS
import Application from './components/Application'

const ApplicationProvider = () => {
  return (
    <SectionProvider>
      <AnswerProvider>
        <FileProvider>
          <StepsProvider>
            <Application />
          </StepsProvider>
        </FileProvider>
      </AnswerProvider>
    </SectionProvider>
  )
}

export default ApplicationProvider
