// COMPONENTS
import ApplicationProvider from './ApplicationProvider'
import ApplicationSelect from './components/ApplicationSelect'
import SingleApplicationSelect from './components/SingleApplicationSelect'
import ApplicationRecovery from './components/ApplicationRecovery'
import CreateMergeRedirect from './components/Redirects/CreateMergeRedirect'
import ApplyRedirect from './components/Redirects/ApplyRedirect'
import SplashLayout from './layouts/Splash'
import RecoverySplash from './layouts/RecoverySplash'
import ApplicationRedirect from './components/Redirects/ApplicationRedirect'
import AdditionalStepsProvider from './components/AdditionalSteps/AdditionalStepsProvider'
import WotcRedirect from './components/AdditionalSteps/WotcRedirect'
import OnboardingWotcCompleted from './components/AdditionalSteps/OnboardingWotcCompleted'

const routes = [
  {
    path: '/',
    exact: true,
    component: CreateMergeRedirect
  },
  {
    path: '/apply/:postingId',
    component: ApplyRedirect
  },
  {
    path: '/postings/:postingId/application',
    layout: SplashLayout,
    component: ApplicationRedirect,
    exact: true
  },
  {
    path: '/postings/:postingId/application/:sectionName',
    layout: SplashLayout,
    component: ApplicationProvider,
    exact: true
  },
  {
    path: '/applicant/applications',
    layout: SplashLayout,
    component: ApplicationSelect,
    exact: true
  },
  {
    path: '/applicant/application',
    layout: SplashLayout,
    component: SingleApplicationSelect,
    exact: true
  },
  {
    path: '/applicant/recover-applications',
    layout: RecoverySplash,
    component: ApplicationRecovery,
    exact: true
  },
  {
    path: '/postings/:postingId/additional-steps',
    layout: SplashLayout,
    component: AdditionalStepsProvider,
    exact: true
  },
  {
    path: '/postings/additional-steps/wotc-redirect',
    layout: SplashLayout,
    component: WotcRedirect,
    exact: true
  },
  {
    path: '/postings/additional-steps/wotc-onboarding-redirect',
    layout: SplashLayout,
    component: OnboardingWotcCompleted,
    exact: true
  }
]

export default routes
