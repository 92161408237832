// PACKAGES
import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
// CONFIG
import { environment } from '../config'
// UTILS
import { escape } from '../util/util'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: {
      format: (value, format) => {
        if (format === 'append_spanish_of') {
          return 'de ' + value
        }
        return value
      },
      escape
    },
    debug: environment !== 'prod',
    fallbackLng: 'en',
    whitelist: ['en', 'es', 'fr']
  })

export default i18n
