import validator from 'validator'
import ValidAnswer from '../models/ValidAnswer'

export default class Validation {
  static validateQuestion (question, answer) {
    if (question.required && question.type === 'multiselect') {
      if (answer.values.length === 0) {
        return new ValidAnswer(false, 'Answer is required.')
      }
    } else if (
      question.required &&
      (!answer.value || validator.isEmpty(answer.value, { ignore_whitespace: true }))
    ) {
      return new ValidAnswer(false, 'Answer is required.')
    } else if (Object.prototype.hasOwnProperty.call(question, 'validation')) {
      if (
        question.validation === 'email' &&
        !Validation.validateEmail(answer.value)
      ) {
        return new ValidAnswer(false, 'Email is invalid.')
      } else if (
        question.validation === 'phone-number' &&
        !Validation.validatePhone(answer.value)
      ) {
        return new ValidAnswer(false, 'Phone Number is invalid.')
      } else if (
        question.validation === 'zip' &&
        !Validation.validateZip(answer.value)
      ) {
        return new ValidAnswer(false, 'ZIP is invalid.')
      } else if (
        question.validation === 'ssn' &&
        !Validation.validateSsn(answer.value)
      ) {
        return new ValidAnswer(false, 'SSN is invalid.')
      }
    }

    return new ValidAnswer(true)
  }

  static validateEmail (email) {
    return validator.isEmail(email)
  }

  static validatePhone (phone) {
    return validator.isMobilePhone(phone, 'en-US')
  }

  static validateZip (zip) {
    return (
      validator.isPostalCode(zip.trim(), 'US') || validator.isPostalCode(zip.trim(), 'CA')
    )
  }

  static validateSsn (ssn) {
    return (/^(?!(000|666|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$/).test(ssn)
  }

  static parseZip (zip) {
    const trimmedZip = zip.trim()
    if (validator.isPostalCode(trimmedZip, 'US')) { // get only the 5 first charactes to avoid the -9999 from zip codes
      return trimmedZip.substring(0, 5)
    } else if (validator.isPostalCode(trimmedZip, 'CA')) { // remove spaces and get first 6
      return trimmedZip.replace(' ', '').substring(0, 6)
    } else {
      const zipWithNoSpecialChars = JSON.stringify(trimmedZip).replace(/[^0-9a-z]/gi, '')
      return zipWithNoSpecialChars.substring(0, 6) // making sure we don't have more than 6 characters no matter what
    }
  }
}
