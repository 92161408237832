// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
import { useContext } from 'use-context-selector'
// UI
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@material-ui/core'
// CONTEXTS
import { AnswerContext } from '../../contexts/AnswerContext'
// COMPONENTS
import QuestionFormLabel from '../Styled/QuestionFormLabel'

// because m-ui `Select` component does not accept `size: 'small'` prop like text component
const sizeSmallPadding = '10.5px'

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: sizeSmallPadding,
    paddingTop: sizeSmallPadding
  }
}))

const UnitSelect = ({
  handleChange,
  question: {
    id,
    options,
    required,
    ...extra
  }
}) => {
  return (
    <FormControl fullWidth component='fieldset' margin='normal'>
      <RadioGroup
        {...extra}
        data-testid='unit-q-radio'
        id={id}
        name={id}
        onChange={handleChange}
        required={required}
      >
        {options.map(({ label, value }) => (
          <FormControlLabel
            control={<Radio color='primary' />}
            data-testid={`unit-q-op-${value}`}
            key={value}
            label={label}
            value={value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default function UnitQuestion ({
  errorMessage,
  handleChangeById,
  question,
  value,
  ...extra
}) {
  const {
    id,
    options,
    required,
    unitQuestion
  } = question
  const classes = useStyles()
  const [answerContext] = useContext(AnswerContext)
  const unitError = answerContext[unitQuestion.id]?.errorMessage

  const handleChange = id => e => { handleChangeById(id, e.target.value) }

  return (
    <>
      <FormControl fullWidth component='fieldset' margin='normal' error={!!unitError}>
        <QuestionFormLabel required={required}>
          <span dangerouslySetInnerHTML={{ __html: question.question }} />
        </QuestionFormLabel>
        {options
          ? (
            <Select
              classes={{ root: classes.root }}
              data-testid='unit-q-select'
              id={id}
              labelId={`${id}-label`}
              onChange={handleChange(id)}
              value={value}
              variant='outlined'
            >
              {options.map(opt => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
            </Select>
            )
          : (
            <TextField
              {...extra}
              data-testid='unit-q-text'
              error={!!errorMessage}
              fullWidth
              helperText={errorMessage}
              id={id}
              name={id}
              onChange={handleChange(id)}
              placeholder=''
              size='small'
              value={value}
              variant='outlined'
              type='number'
            />
            )}
        <UnitSelect
          handleChange={handleChange(unitQuestion.id)}
          question={unitQuestion}
        />
        {!!unitError && <FormHelperText data-testid='unit-q-error'>{unitError}</FormHelperText>}
      </FormControl>
    </>
  )
}

UnitQuestion.propTypes = {
  errorMessage: PropTypes.string,
  handleChangeById: PropTypes.func.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    unitQuestion: PropTypes.shape({
      id: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
          ]),
          value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
          ])
        }).isRequired
      ).isRequired,
      question: PropTypes.string.isRequired,
      required: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
}
