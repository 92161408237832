// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
import { useContext } from 'use-context-selector'
import styled from 'styled-components'
// UI
import Typography from '@material-ui/core/Typography'
// CONTEXTS
import { AnswerContext } from '../../contexts/AnswerContext'

const AnswerLabel = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`

const UnitAnswer = ({ question, answer }) => {
  const [answerContext] = useContext(AnswerContext)
  const noAnswer = '--'

  const getAnswerLabel = () => {
    const { id: unitId, options } = question.unitQuestion
    const selectedUnit = options.find(op => op.value === answerContext[unitId]?.value)
    return answer && selectedUnit
      ? `${answer.value} ${selectedUnit.label}`
      : noAnswer
  }

  return <AnswerLabel data-testid='unit-answer'>{getAnswerLabel()}</AnswerLabel>
}

UnitAnswer.propTypes = {
  answer: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  }),
  question: PropTypes.shape({
    question: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired
}

export default UnitAnswer
