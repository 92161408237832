// PACKAGES
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
// UI
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText
} from '@material-ui/core'

const MainSelectFormControl = styled(FormControl)`
   {
    width: 49%;
    margin-right: 2%;
    margin-top: 16px;

    .MuiFormLabel-root {
      color: rgb(0,0,0);
      padding-top: 0px;
      padding-left: 10px;
    }

    .MuiInputLabel-marginDense:not(.MuiInputLabel-shrink) {
      transform: translate(0, 12px) scale(1);
    }
  }
`

const SecondarySelectFormControl = styled(FormControl)`
   {
    width: 49%;
    margin-top: 16px;

    .MuiFormLabel-root {
      color: rgb(0,0,0);
    }
  }
`

export default function HierarchicalQuestion ({
  errorMessage,
  question,
  value = '',
  ...args
}) {
  const [condition, setCondition] = useState('')

  const isError = errorMessage.length !== 0

  useEffect(() => {
    if (value) {
      question.hierarchicalOptions.forEach(hierarchicalOpt => {
        return hierarchicalOpt.options.some(opt => {
          if (value === opt.value) {
            setCondition(hierarchicalOpt.condition.value)
            return true
          }
          return false
        })
      })
    }
  }, [value, question.hierarchicalOptions])

  return (
    <div>
      <MainSelectFormControl required={question.required} error={isError && condition === ''} size='small'>
        <InputLabel size='small' id={question.id + '-label'} data-testid={question.id + '-label'}>
          {question.question}
        </InputLabel>

        <Select
          variant='outlined'
          value={condition}
          labelId={question.id + '-label'}
          label={question.question}
          size='small'
          id={question.id + '-main'}
          onChange={e => setCondition(e.target.value)}
        >
          {question.options.map(opt => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </MainSelectFormControl>

      {question.hierarchicalOptions.map(
        hierarchicalOpt =>
          condition === hierarchicalOpt.condition.value && (
            <SecondarySelectFormControl
              variant='outlined'
              key={hierarchicalOpt.id}
              error={isError}
              size='small'
            >
              <InputLabel id={hierarchicalOpt.id + '-label'} />
              <Select
                {...args}
                labelId={hierarchicalOpt.id + '-label'}
                id={hierarchicalOpt.condition.id}
                name={hierarchicalOpt.condition.id}
                value={value}
              >
                {hierarchicalOpt.options.map(opt => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            </SecondarySelectFormControl>
          )
      )}

      {isError && <FormHelperText error>{errorMessage}</FormHelperText>}
    </div>
  )
}

HierarchicalQuestion.propTypes = {
  errorMessage: PropTypes.string,
  question: PropTypes.shape({
    hierarchicalOptions: PropTypes.arrayOf(
      PropTypes.shape({
        condition: PropTypes.shape({
          value: PropTypes.string
        }),
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string
          })
        )
      })
    ),
    id: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any
      })
    ),
    question: PropTypes.any,
    required: PropTypes.any
  }),
  value: PropTypes.any
}
