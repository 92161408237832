import qs from 'query-string'

export const alertUser = e => {
  e.preventDefault()
  e.returnValue = ''
}

// via pkg src: https://github.com/i18next/i18next/blob/b3f9d4aaa1e0d1e4ab2006402b37c37406bda53a/src/utils.js#L118 for i18n.js config
export const _entityMap = {
  '<': '&lt;',
  '>': '&gt;'
}
export const escape = data => {
  if (typeof data === 'string') {
    return data.replace(/[<>]/g, s => _entityMap[s])
  }
  return data
}

/**
 * A fn that takes one query string as an argument (pulled from the browser window)
 * and returns a new query string (to be sent to our api)
 */
export const transformQueryString = queryString => {
  if (!queryString) return ''

  const parsedQPs = qs.parse(queryString)
  const transformedQPs = Object.entries(parsedQPs).reduce((acc, [key, val]) => {
    switch (key) {
      case 'internalApplicant':
        acc.isInternalApplicant = val
        break
      default:
        acc[key] = val
    }
    return acc
  }, {})

  return `?${qs.stringify(transformedQPs)}`
}

/**
   * This function will return the first element of an array if it exists, otherwise it will return the element itself
   * Using it to ignore when we have repeated url parameters
   */
export const getFirstParam = param => {
  if (!param) {
    return undefined
  } else if (Array.isArray(param)) {
    if (param.length > 0) {
      return param[0]
    } else {
      return undefined
    }
  } else {
    return param
  }
}
