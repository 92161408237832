// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
import { useContext } from 'use-context-selector'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
// UI
import { Button, Grid, Link, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ClearIcon from '@material-ui/icons/Clear'
// MODELS
import Answer from '../../models/Answer'
// SERVICES
import Validation from '../../services/validation'
import Postings from '../../services/postings'
import Redirect from '../../services/redirect'
// CONFIG
import { jobDescriptionURL } from '../../config'
// HOOKS
import { useMountEffect } from '../../util/hooks'
// CONTEXTS
import { AnswerContext } from '../../contexts/AnswerContext'
import { SectionContext } from '../../contexts/SectionContext'
// COMPONENTS
import Question from '../Questions/Question'
import PageHeaderTypography from '../Styled/PageHeaderTypography'

const Footer = styled.div`
  width: 100%;
  text-align: right;
  margin-right: 10px;
`

const NumberedEntryHeader = styled(Typography)`
&& {
  font-weight: 500;
  font-size: 20px;
  color: #404040;
}
`

const MultiSectionItemHeader = styled(Grid)`
&& {
  display: flex;
  justify-content: space-between;
}
`

const AddButton = styled(Button)`
&& {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
`

const MultiSection = ({ section, onMoveNextSection }) => {
  const [answerContext, answerDispatch] = useContext(AnswerContext)
  const [sectionContext, sectionDispatch] = useContext(SectionContext)

  const { postingId } = useParams()
  const { t } = useTranslation()

  const leadingQuestion = {
    id: section.name + '-question',
    question: section.multiplier.question,
    required: true,
    type: 'select',
    options: [
      {
        value: '1',
        label: t('yes')
      },
      {
        value: '0',
        label: t('no')
      }
    ]
  }

  if (sectionContext[section.name] === undefined) {
    sectionDispatch({ id: section.name, value: { multiplicity: 0, completed: false } })
  }

  const saveSectionData = () => {
    let missingField = false
    // Check the leading question
    const leadingAnswer = answerContext[leadingQuestion.id] || new Answer('', '')
    const leadingValidation = Validation.validateQuestion(leadingQuestion, leadingAnswer)
    if (!leadingValidation.isValid) {
      answerDispatch({
        id: leadingQuestion.id,
        answer: new Answer(leadingAnswer.value, leadingValidation.message, leadingAnswer.values)
      })
      return false
    }
    // Check the rest of the questions
    for (let idx = 1; idx < sectionContext[section.name].multiplicity + 1; idx++) {
      for (const question of section.questions) {
        const answer = answerContext[question.id + idx] || new Answer('', '')
        const condId = question.condition?.id
        const condValue = question.condition?.value

        if (condId && answerContext[(condId + idx)]?.value !== condValue) {
          // passing undefined for `answer` allows reducer to clear value
          answerDispatch({ id: question.id + idx, answer: undefined })
        } else {
          const answerValidation = Validation.validateQuestion(question, answer)
          if (!answerValidation.isValid) {
            answerDispatch({
              id: question.id + idx,
              answer: new Answer(answer.value, answerValidation.message, answer.values)
            })
            missingField = true
          }
        }
      }
    }
    if (missingField) {
      return false
    }
    sectionDispatch({
      id: section.name,
      value: { ...sectionContext[section.name], completed: true }
    })
    onMoveNextSection(section.name)
    return true
  }

  const cancelApplication = async () => {
    // refetch posting so cancelling app works on refresh
    const posting = await Postings.getPosting(postingId)
    const jobDescriptionPage = `${jobDescriptionURL}/clients/${posting.clientId}/posting/${postingId}`
    Redirect.navigate(jobDescriptionPage)
  }

  const addMultiplicityItem = () => {
    if (sectionContext[section.name].multiplicity < section.multiplier.max) {
      sectionDispatch({ id: section.name, value: { ...sectionContext[section.name], multiplicity: sectionContext[section.name].multiplicity + 1 } })
    }
  }

  const removeMultiplicityItem = (idx) => {
    if (sectionContext[section.name].multiplicity > section.multiplier.min) {
      // Move entries after the delete up
      for (let i = idx + 1; i < sectionContext[section.name].multiplicity + 1; i++) {
        const prevIdx = i - 1
        const currentIdx = i
        section.questions.forEach(question => {
          answerDispatch({ id: question.id + prevIdx, answer: answerContext[question.id + currentIdx] })
        })
      }

      // Remove the data for the last entry
      section.questions.forEach(question => {
        answerDispatch({ id: question.id + sectionContext[section.name].multiplicity, answer: null })
      })

      sectionDispatch({ id: section.name, value: { ...sectionContext[section.name], multiplicity: sectionContext[section.name].multiplicity - 1 } })
    }
  }

  const renderSectionQuestions = (section, multiplicityIdx) => {
    return section.questions.map(question => {
      const currentQuestion = { ...question, id: question.id + multiplicityIdx, parentId: question.parentId + multiplicityIdx }

      const showQuestion = (() => {
        if (
          Object.prototype.hasOwnProperty.call(currentQuestion, 'condition') &&
          answerContext[currentQuestion.condition.id + multiplicityIdx]
        ) {
          return (
            answerContext[currentQuestion.condition.id + multiplicityIdx].value ===
            currentQuestion.condition.value
          )
        } else if (
          Object.prototype.hasOwnProperty.call(currentQuestion, 'condition')
        ) {
          return false
        } else {
          return true
        }
      })()

      return (
        showQuestion && (
          <Grid
            key={currentQuestion.id}
            item
            xs={12}
          >
            <Question
              question={currentQuestion}
              answer={answerContext[currentQuestion.id]}
              dispatch={answerDispatch}
            />
          </Grid>
        )
      )
    })
  }

  const leadingDispatch = answer => {
    sectionDispatch({ id: section.name, value: { ...sectionDispatch[section.name], multiplicity: answer.answer.value === '1' ? 1 : 0 } })
    answerDispatch(answer)
  }

  useMountEffect(() => { window.scrollTo(0, 0) })

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <PageHeaderTypography variant='h6'>
                {section.title}
              </PageHeaderTypography>
            </Grid>
            <Question
              question={leadingQuestion}
              answer={answerContext[leadingQuestion.id]}
              dispatch={leadingDispatch}
            />
            {answerContext[leadingQuestion.id]?.value === '1' && sectionContext[section.name]
              ? Array(sectionContext[section.name].multiplicity).fill().map((_, idx) => {
                  const employmentIdx = idx + 1
                  return (
                    <React.Fragment key={section.name + employmentIdx}>
                      <MultiSectionItemHeader item xs={12}>
                        <NumberedEntryHeader>{`${section.description} #${employmentIdx}`}</NumberedEntryHeader>
                        <Button
                          onClick={() => removeMultiplicityItem(employmentIdx)}
                        >
                          <span>{t('delete')}</span>
                          <ClearIcon />
                        </Button>
                      </MultiSectionItemHeader>
                      {renderSectionQuestions(section, employmentIdx)}
                    </React.Fragment>
                  )
                })
              : null}
            {answerContext[leadingQuestion.id]?.value === '1' && (
              <Grid
                item
                xs={12}
              >
                <AddButton
                  varaint='outlined'
                  color='primary'
                  disabled={sectionContext[section.name]?.multiplicity >= section.multiplier.max || false}
                  onClick={() => addMultiplicityItem()}
                >
                  <AddIcon /><span>{t('add-another')}</span>
                </AddButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Footer>
        <Grid container spacing={4} justifyContent='flex-end' alignItems='center'>
          <Grid item>
            <Link href='#' onClick={() => cancelApplication()} variant='body2'>
              {t('cancel-button')}
            </Link>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              onClick={() => saveSectionData()}
            >
              {t('continue-button')}
            </Button>
          </Grid>
        </Grid>
      </Footer>
    </>
  )
}

MultiSection.propTypes = {
  onMoveNextSection: PropTypes.func,
  section: PropTypes.shape({
    multiplier: PropTypes.shape({
      max: PropTypes.any,
      min: PropTypes.any,
      question: PropTypes.string
    }),
    name: PropTypes.string,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        condition: PropTypes.shape({
          id: PropTypes.any,
          value: PropTypes.any
        })
      })
    )
  })
}

export default MultiSection
