// PACKAGES
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash.throttle'
import { useContext } from 'use-context-selector'
// UI
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
// MODELS
import Answer from '../../models/Answer'
// CONFIG
import { postgridApiKey, postgridUrl } from '../../config'
// CONTEXTS
import { AnswerContext } from '../../contexts/AnswerContext'

// <KEY>: PostGrid API key name
// <VALUE>: TR API key name
export const fieldMap = {
  address: 'address-street',
  address2: 'address-street2',
  city: 'address-city',
  country: 'address-country',
  pc: 'address-zip',
  prov: 'address-state'
}

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  }
}))

/**
 * Component for used for auto-completing address-related questions with the PostGrid API
 *
 * It is worth noting that the `Autocomplete` component this is built on is from @material-ui/lab,
 * which is for beta-version experiments. Keep an eye on this to update it to a more stable
 * version whenever it is eventually integrated into @material-ui/core (coming in version 5).
 *
 * Docs for the PostGrid integration can be found: `https://avdocs.postgrid.com/#bb50d2cd-d648-49c6-bf03-25fdffcefa9e`
 *
 * @component
 */
export default function AutoCompleteQuestion ({
  dispatch,
  errorMessage,
  question: {
    id,
    question,
    required,
    limit
  }
}) {
  const { icon } = useStyles()

  const [answerContext] = useContext(AnswerContext)
  const initialInputValue = answerContext[id]?.value || ''

  const [inputValue, setInputValue] = useState(initialInputValue)
  const [options, setOptions] = useState([])
  const [value, setValue] = useState(null)

  const fetchPreviews = useMemo(() =>
    throttle(async (partialStreet, fn) => {
      try {
        // first, we throttle GET requests to return partial preview data for each address while a user types
        const url = `${postgridUrl}?partialStreet=${encodeURIComponent(partialStreet)}&properCase=true`
        const response = await window.fetch(url, { headers: { 'x-api-key': postgridApiKey } })
        const json = await response.json()
        // handle the possibility of json.data not being an array which crashes the component
        const data = json.data || []
        fn(data)
      } catch (e) {
        console.warn(e)
        fn([])
      }
    }, 200),
  [])
  const fetchCompleteData = async idx => {
    // when we pick an address, we want to POST to the same endpoint to get back full data for the address
    try {
      // kind of funky, but it's how their docs specify this should be done and doesn't work otherwise
      const body = new URLSearchParams()
      body.append('partialStreet', inputValue)
      // providing an index query param returns only the single already selected result
      const response = await window.fetch(`${postgridUrl}?index=${idx}&properCase=true`, {
        method: 'POST',
        headers: { 'x-api-key': postgridApiKey },
        body
      })
      const json = await response.json()
      // handle the possibility of json.data not being an array which crashes the component
      const data = json.data || []
      return data
    } catch (e) {
      console.warn(e)
      return []
    }
  }

  // called when text input is typed into
  const handleInputChange = (e, newValue) => {
    e?.preventDefault()
    const limitedValue = limit ? newValue.substring(0, limit) : newValue
    setInputValue(limitedValue)
    dispatch({ id, answer: new Answer(limitedValue, '') })
  }

  const correctField = (id, value) => {
    if (id === 'address-zip') {
      return value.replace(/-\d+/, '')
    } else {
      return value
    }
  }

  // called when new selection is made from results dropdown
  const handleChange = async (e, newValue) => {
    e?.preventDefault()
    setValue(newValue)
    if (newValue) {
      // if value selected from autofill menu then update all relevant fields with associated data after POST
      const idx = options.indexOf(newValue)
      const response = await fetchCompleteData(idx)
      const result = Array.isArray(response) ? response[0] : response
      const address = result?.address || {}
      Object.entries(address).forEach(([key, value]) => {
        // since we update `address-street` in `handleInputChange`, we skip it here
        if (key !== 'address') {
          dispatch({ id: fieldMap[key], answer: new Answer(correctField(fieldMap[key], value), '') })
        }
      })
    } else {
      // if autofilled value is cleared, clear all other values that had been previously autofilled as well
      Object.values(fieldMap).forEach(qId => {
        dispatch({ id: qId, answer: new Answer('', '') })
      })
    }
  }

  useEffect(() => {
    let active = true
    inputValue.length && active && fetchPreviews(inputValue, setOptions)
    return () => { active = false }
  }, [value, inputValue, fetchPreviews])

  return (
    <Autocomplete
      autoComplete
      data-testid='autocomplete-field'
      filterSelectedOptions
      getOptionLabel={op => (typeof op === 'string' ? op : op.preview.address)}
      getOptionSelected={(op, value) => value === Object.values(op.preview).join(', ')}
      id={id}
      includeInputInList
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={options}
      renderInput={params => (
        <>
          <TextField
            {...params}
            data-testid='text-field'
            error={errorMessage.length > 0}
            fullWidth
            helperText={errorMessage}
            label={question}
            margin='normal'
            name={id}
            required={required}
            value={inputValue}
            size='small'
            variant='outlined'
          />
        </>
      )}
      renderOption={({ preview }) => (
        <Grid container alignItems='center'>
          <Grid item>
            <LocationOnIcon className={icon} />
          </Grid>
          <Grid item xs>
            {Object.values(preview).join(', ')}
          </Grid>
        </Grid>
      )}
      // allow the chosen value to be overridden with custom text:
      value={inputValue || value}
    />
  )
}

AutoCompleteQuestion.propTypes = {
  dispatch: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired
  }).isRequired
}
