// PACKAGES
import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useContext } from 'use-context-selector'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
// UI
import { Button, Grid } from '@material-ui/core'
import Link from '@material-ui/core/Link'
// MODELS
import Answer from '../../models/Answer'
// SERVICES
import Validation from '../../services/validation'
import Postings from '../../services/postings'
import Redirect from '../../services/redirect'
// CONTEXTS
import { AnswerContext } from '../../contexts/AnswerContext'
import { FileContext } from '../../contexts/FileContext'
import { SectionContext } from '../../contexts/SectionContext'
// CONFIG
import { jobDescriptionURL } from '../../config'
// HOOKS
import { useMountEffect } from '../../util/hooks'
// UTILS
import { CONTACT_INFO, TRACKING_START } from '../../util/consts'
// COMPONENTS
import FileUploader from '../FileUploader/FileUploader'
import PageHeaderTypography from '../Styled/PageHeaderTypography'
import Question from '../Questions/Question'
import TrackingPixel from '../Utility/TrackingPixel'

const Footer = styled.div`
  width: 100%;
  text-align: right;
  margin-right: 10px;
`

const GridQuestion = styled(Grid)`
  flex-grow: 1;
`

const handleDispatch = (questionId, question, answer, dispatch) => {
  const answerValidation = Validation.validateQuestion(question, answer)
  if (!answerValidation.isValid) {
    dispatch({
      id: questionId,
      answer: new Answer(answer.value, answerValidation.message, answer.values)
    })
    return true
  }
  return false
}

const Section = ({ section, onMoveNextSection, resumeSection = {} }) => {
  const { t } = useTranslation()

  const questionRefs = useRef({})
  const resumeRef = useRef()

  const [answerContext, answerDispatch] = useContext(AnswerContext)
  const [fileContext] = useContext(FileContext)
  const [, sectionDispatch] = useContext(SectionContext)

  const [resumeError, setResumeError] = useState(false)

  const { postingId } = useParams()

  const saveSectionData = () => {
    let missingField = null

    const resumeShouldError = resumeSection.require && !Object.keys(fileContext).length
    setResumeError(resumeShouldError)

    if (resumeShouldError) {
      resumeRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    for (const question of section.questions) {
      let isFieldMissing = false
      const answer = answerContext[question.id] || new Answer('', '')
      const condId = question.condition?.id
      const condValue = question.condition?.value

      if (condId && answerContext[condId]?.value !== condValue) {
        // passing undefined for `answer` allows reducer to clear value
        answerDispatch({ id: question.id, answer: undefined })
      } else {
        if (question.type === 'preference') {
          for (let i = 1; i <= question.numberOfPreferences; i++) {
            if (i > 1) {
              question.required = false
            }
            const preferenceId = question.id + i
            const preferenceAnswer = answerContext[preferenceId] || new Answer('', '')
            isFieldMissing = handleDispatch(preferenceId, question, preferenceAnswer, answerDispatch) || isFieldMissing
          }
        } else if (question.type === 'hierarchical-secondary') {
          const primaryAnswer = answerContext[question.parentId]
          if (primaryAnswer === undefined ||
            primaryAnswer === '' ||
            question.hierarchicalOptions.some(el => el.condition.value === primaryAnswer.value)) {
            isFieldMissing = handleDispatch(question.id, question, answer, answerDispatch) || isFieldMissing
          }
        } else {
          isFieldMissing = handleDispatch(question.id, question, answer, answerDispatch) || isFieldMissing
          if (question.type === 'unit') {
            const { unitQuestion } = question
            const unitAnswer = answerContext[unitQuestion.id] || new Answer('', '')
            isFieldMissing = handleDispatch(unitQuestion.id, unitQuestion, unitAnswer, answerDispatch) || isFieldMissing
          }
        }
      }
      if (isFieldMissing) {
        missingField = missingField || questionRefs.current[question.id]
      }
    }

    if (missingField) {
      missingField.scrollIntoView({ behavior: 'smooth' })
    }

    if (missingField || resumeShouldError) {
      return false
    }

    sectionDispatch({
      id: section.name,
      value: { completed: true }
    })

    onMoveNextSection(section.name)

    return true
  }

  useMountEffect(() => { window.scrollTo(0, 0) })

  const cancelApplication = async () => {
    // refetch posting so cancelling app works on refresh
    const posting = await Postings.getPosting(postingId)
    const jobDescriptionPage = `${jobDescriptionURL}/clients/${posting.clientId}/posting/${postingId}`
    Redirect.navigate(jobDescriptionPage)
  }

  const renderResumeSection = resumeSection.show && section.name === CONTACT_INFO

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <PageHeaderTypography variant='h6'>
                {section.description}
              </PageHeaderTypography>
            </Grid>
            {section.questions.map(question =>
              (!question.condition || answerContext[question.condition.id]?.value === question.condition.value) && (
                <GridQuestion
                  key={question.id}
                  item
                  xs={12}
                >
                  <Question
                    question={question}
                    answer={answerContext[question.id]}
                    dispatch={answerDispatch}
                    ref={element => { questionRefs.current[question.id] = element }}
                  />
                </GridQuestion>
              )
            )}
            {renderResumeSection && (
              <>
                <Grid item xs={12}>
                  <hr />
                  <PageHeaderTypography variant='h6'>
                    {`${t('resume-header')} ${resumeSection.require ? '' : t('optional-statement')}`}
                  </PageHeaderTypography>
                  <PageHeaderTypography data-testid='resume-heading' color={resumeError ? 'error' : 'initial'}>
                    {`${t('resume-prompt')} ${resumeSection.require ? '*' : ''}`}
                  </PageHeaderTypography>
                </Grid>
                <Grid item xs={12}>
                  <FileUploader handleChange={() => setResumeError(false)} ref={resumeRef} required={resumeSection.require} />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Footer>
        <Grid container spacing={4} justifyContent='flex-end' alignItems='center'>
          <Grid item>
            <Link href='#' onClick={() => cancelApplication()} variant='body2'>
              {t('cancel-button')}
            </Link>
          </Grid>
          <Grid item>
            <Button
              color='primary'
              data-testid='continue-btn'
              onClick={saveSectionData}
              variant='contained'
            >
              {t('continue-button')}
            </Button>
          </Grid>
        </Grid>
      </Footer>
      <TrackingPixel eventName={TRACKING_START} />
    </div>
  )
}

Section.propTypes = {
  onMoveNextSection: PropTypes.func.isRequired,
  resumeSection: PropTypes.shape({
    show: PropTypes.bool,
    require: PropTypes.bool
  }),
  section: PropTypes.shape({
    name: PropTypes.string.isRequired,
    questions: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
}

export default Section
