// PACKAGES
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
// UI
import { Grid, Card, Typography } from '@material-ui/core'
import { CheckCircleOutline } from '@material-ui/icons'

const GrayTypography = styled(Typography)`
  color: #2e2e2e;
`

const PaddedGrayTypography = styled(GrayTypography)`
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: .8rem;
`

const Message = styled(Typography)`
  padding: 1rem;
  padding-bottom: 0;
`

const SuccessCheckmark = styled(CheckCircleOutline)`
  padding-top: 1rem;
  color: #b5d443;
  font-size: xx-large;
`

const ApplicationCompleteCards = ({
  clientName,
  username
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Grid item xs={12}>
        <Card raised>
          <SuccessCheckmark />
          <Message variant='h6'>
            {t('application-complete-message', { clientName })}
          </Message>
          <GrayTypography>
            {t('application-complete-confirmation')}
          </GrayTypography>
          <GrayTypography>
            {!!username && username}
          </GrayTypography>
          <PaddedGrayTypography>
            {t('check-email')}
          </PaddedGrayTypography>
        </Card>
      </Grid>
    </>
  )
}

export default ApplicationCompleteCards
