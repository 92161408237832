import { Amplify, Analytics } from 'aws-amplify'

const defaultEvent = {
  sdkName: 'aws-amplify',
  clientSdkVersion: '3.3.26',
  appPackageName: 'AmplifyService',
  appTitle: 'applicant-ui',
  appVersionCode: 'v1'
}

export default class AmplifyService {
  static initialize (identityPoolId, appId) {
    const region = identityPoolId.split(':')[0]
    Amplify.configure({
      Auth: {
        identityPoolId,
        region
      },
      Analytics: {
        disabled: false,
        autoSessionRecord: false,
        AWSPinpoint: {
          appId,
          region,
          endpointId: 'applicant-ui',
          bufferSize: 1000,
          flushInterval: 5000, // 5s
          flushSize: 100,
          resendLimit: 5
        }
      }
    })
  }

  static sendEvent (event) {
    try {
      Analytics.record({ ...defaultEvent, timestamp: new Date().toISOString(), ...event })
    } catch (e) {
      console.error('Error sending Amplify event', e)
    }
  }
}
