// PACKAGES
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import qs from 'query-string'
// UI
import { Box } from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import WarningIcon from '@material-ui/icons/WarningTwoTone'
// COMPONENTS
import StatusLabel from '../Styled/StatusLabel'
// UTILS
import device from '../../util/device'
import { isShowApplicationComplete, linkTextForApplicationRow, statusForApplicationRow } from '../../util/status'

const ApplicationsDataGridContainer = styled(Box)`
  height: 100%;
  width: 100%;
`

const IncompleteIcon = styled(WarningIcon)`
  color: orange;
`

const ViewApplicationLink = styled.a`
  color: #2A5DB7;
  font-weight: 500;
`

const ApplicationsTable = ({ applications }) => {
  const { t } = useTranslation()

  const columns = [
    {
      headerName: t('position'),
      field: 'positionTitle',
      flex: 1
    },
    {
      headerName: t('brand'),
      field: 'brand',
      flex: 1
    },
    {
      headerName: t('location'),
      field: 'location',
      flex: 1,
      renderCell: params => (
        <span>
          <span>{`${params.row.street} ${params.row.street2 ? params.row.street2 : ''}`}</span>
          <br />
          <span>{`${params.row.city}, ${params.row.state} ${params.row.zipCode}`}</span>
        </span>
      ),
      valueGetter: params => (
        params.row.state + params.row.city + params.row.street + params.row.street2
      )
    },
    {
      headerName: t('date-applied'),
      field: 'appliedDate',
      type: 'dateTime',
      flex: 0.8,
      renderCell: params => (
        new Date(Date.parse(`${params.value.split(/\s+/)[0]}T12:00:00.000Z`)).toLocaleDateString()
      )
    },
    {
      headerName: t('status'),
      field: 'status',
      renderCell: params => (<><StatusLabel status={statusForApplicationRow(params.row)} /></>),
      flex: 1
    },
    {
      headerName: '',
      field: 'viewApplication',
      flex: 2,
      sortable: false,
      renderCell: params => (
        <>
          <ViewApplicationLink href={qs.stringifyUrl({
            url: qs.parseUrl(params.value).url,
            query: { token: params.row.token }
          })}
          ><span>{t(linkTextForApplicationRow(params.row))}</span>
          </ViewApplicationLink>
          {isShowApplicationComplete(params.row) ? null : <IncompleteIcon />}
        </>
      )
    }
  ]

  return (
    <ApplicationsDataGridContainer>
      <DataGrid
        columns={columns}
        disableSelectionOnClick
        disableColumnMenu
        autoHeight
        rowsPerPageOptions={[10]}
        pageSize={10}
        rows={applications}
        sx={{
          '.MuiDataGrid-columnSeparator': {
            display: 'none'
          },
          '&.MuiDataGrid-root': {
            border: 'none'
          },
          '.MuiDataGrid-columnHeaders': {
            backgroundColor: '#F5F5F5',
            fontSize: '1rem',
            paddingLeft: '80px',
            [`@media ${device.tablets}`]: {
              paddingLeft: '20px'
            }
          },
          '.MuiDataGrid-cell': {
            border: 'none'
          },
          '.MuiDataGrid-row': {
            paddingLeft: '80px',
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            [`@media ${device.tablets}`]: {
              paddingLeft: '20px'
            }
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          }
        }}
      />
    </ApplicationsDataGridContainer>
  )
}

export default ApplicationsTable
