// PACKAGES
import React, { useReducer } from 'react'
import { createContext } from 'use-context-selector'
import PropTypes from 'prop-types'

export const AnswerContext = createContext()

export const AnswerProvider = ({ children, initialState = {} }) => {
  const reducer = (state, action) => {
    if (action.answer === undefined && state[action.id]) {
      // passing undefined as an answer value should allow the reducer to delete a field
      // i.e. in the case of clearing a conditional answer whose condition is no longer met
      const newState = { ...state }
      delete newState[action.id]
      return newState
    }
    return { ...state, [action.id]: action.answer }
  }

  const value = useReducer(reducer, initialState)

  return (
    <AnswerContext.Provider value={value}>
      {children}
    </AnswerContext.Provider>
  )
}

AnswerProvider.propTypes = {
  children: PropTypes.any,
  initialState: PropTypes.object
}
