// PACKAGES
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
// UI
import Grid from '@material-ui/core/Grid'
// SERVICES
import Profile from '../../http/profile'
// HOOKS
import { useQuery } from '../../util/hooks'
// COMPONENTS
import ProfileAction from '../Messaging/ProfileAction'
import RedirectMessage from '../Messaging/RedirectMessage'

const AppGrid = styled(Grid)`
   {
    min-height: 100vh;
    align-items: center;
    justify-content: center;
  }
`

const CreateMergeRedirect = () => {
  const [actionStatus, setActionStatus] = useState('creating')
  const query = useQuery()

  const isFinishedCreating = () => {
    setActionStatus('merging')
  }

  const isFinishedMerging = () => {
    if (query.get('arete-apply') === 'true') {
      setActionStatus('redirect-arete')
    } else {
      setActionStatus('redirect-legacy')
    }
  }

  return (
    <AppGrid container>
      <Grid item xs={3}>
        {actionStatus === 'creating' && (
          <ProfileAction
            action='creating'
            actionMethod={Profile.create}
            onComplete={isFinishedCreating}
          />
        )}

        {actionStatus === 'merging' && (
          <ProfileAction
            action='merging'
            actionMethod={Profile.merge}
            onComplete={isFinishedMerging}
          />
        )}

        {actionStatus === 'redirect-legacy' && <RedirectMessage />}

        {actionStatus === 'redirect-arete' && (
          <Redirect to={`/postings/${query.get('posting-id')}/application`} />
        )}
      </Grid>
    </AppGrid>
  )
}

export default CreateMergeRedirect
