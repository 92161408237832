// PACKAGES
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
// UI
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText
} from '@material-ui/core'
import QuestionFormLabel from '../Styled/QuestionFormLabel'

export default function MultiSelectQuestion ({
  errorMessage,
  handleMultipleChange,
  question: {
    id,
    options,
    question,
    required
  },
  selected,
  ...props
}) {
  const isError = !!errorMessage
  const [allChecked, setAllChecked] = useState(false)
  const { t } = useTranslation()

  const handleChange = ({ target: { checked, value } }) => {
    const updatedValues = checked
      ? [...selected, value]
      : selected.filter(opt => opt !== value)

    handleMultipleChange(updatedValues)
    updateAllChecked(updatedValues)
  }

  const updateAllChecked = (updatedValues) => {
    setAllChecked(updatedValues.length === options.length)
  }

  const handleCheckAll = ({ target: { checked } }) => {
    if (checked) {
      handleMultipleChange(options.map(({ value }) => value))
      setAllChecked(true)
    } else {
      handleMultipleChange([])
      setAllChecked(false)
    }
  }

  return (
    <FormControl component='fieldset' margin='normal' error={isError}>
      <QuestionFormLabel required={required}>
        <span dangerouslySetInnerHTML={{ __html: question }} />
      </QuestionFormLabel>
      <FormGroup
        {...props}
        id={id}
        name={id}
      >
        <FormControlLabel
          value='all'
          control={
            <Checkbox color='primary' checked={allChecked} />
          }
          onChange={handleCheckAll}
          label={t('all')}
          data-testid='multi-q-op-all'
        />

        {options.map(({ label, value }) => {
          return (
            <FormControlLabel
              key={value}
              value={value}
              control={
                <Checkbox color='primary' checked={selected.includes(value)} />
              }
              onChange={handleChange}
              label={label}
              data-testid={`multi-q-op-${value}`}
            />
          )
        })}
      </FormGroup>
      {isError && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

MultiSelectQuestion.propTypes = {
  errorMessage: PropTypes.string,
  handleMultipleChange: PropTypes.func.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string
        ]),
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string
        ])
      })
    ).isRequired,
    question: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired
  }).isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  ).isRequired
}
