// PACKAGES
import React, { useEffect, useState } from 'react'
// UI
import WotcDisclaimer from './WotcDisclaimer'
import WotcAssessment from './WotcAssessment'
import Redirect from '../../services/redirect'
// CONTEXTS
import { useContext } from 'use-context-selector'
import { StepsContext } from '../../contexts/StepsContext'
// CONFIG
import { legacyApplyURL } from '../../config'
// SERVICES
import ProfileService from '../../services/profile'

const WotcStep = (props) => {
  const [stepsContext, stepsDispatch] = useContext(StepsContext)
  const [activeSection, setActiveSection] = useState(props.actSection)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        const path = ProfileService.parseApplyUrl(props.redirect)
        Redirect.navigate(legacyApplyURL + path + window.location.search)
      }, 5000)
    }
  }, [props, isError])

  const handleNewSection = (section) => {
    setActiveSection(section)
  }

  const handleNext = () => {
    // Only WOTC for now, mark complete then redirect immediately
    const wotcStep = { ...stepsContext.wotc, isComplete: true }
    stepsDispatch({ id: 'wotc', value: wotcStep })

    const path = ProfileService.parseApplyUrl(props.redirect)
    Redirect.navigate(legacyApplyURL + path + window.location.search)
  }

  const onLoad = () => {
    try {
      if (props.redirect && document.getElementById('wotc-redirect').contentWindow.document) {
        const path = ProfileService.parseApplyUrl(props.redirect)
        Redirect.navigate(legacyApplyURL + path + window.location.search)
      }
    } catch (error) {}
  }

  const onError = () => {
    setIsError(true)
  }

  switch (activeSection) {
    case 'disclaimer':
      return (<WotcDisclaimer onHandleNext={handleNext} handleNewSection={handleNewSection} />)
    case 'assessment':
      return (<WotcAssessment onError={onError} onLoad={onLoad} onHandleNext={handleNext} handleNewSection={handleNewSection} />)
    default:
      return null
  }
}

export default WotcStep
