// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// UI
import { TextField, FormControl, InputAdornment } from '@material-ui/core'
// COMPONENTS
import QuestionFormLabel from '../Styled/QuestionFormLabel'

const FixedWidthTextField = styled(TextField)`
  && {
    width: 470px;

    @media screen and (max-width: 510px) {
      width: calc(100vw - 40px);
    }
  }
`

export default function TextAreaQuestion ({
  errorMessage,
  question: {
    id,
    limit,
    question,
    required
  },
  ...extra
}) {
  const inputProps = {
    maxLength: limit
  }

  const upperCaseInputProps = {
    style: {
      flexDirection: 'row-reverse'
    },
    startAdornment: limit ? <InputAdornment position='start'> {limit} max.</InputAdornment> : null
  }

  const inputLabelProps = {}

  return (
    <FormControl component='fieldset' margin='normal'>
      <QuestionFormLabel required={required}>
        <span dangerouslySetInnerHTML={{ __html: question }} />
      </QuestionFormLabel>
      <FixedWidthTextField
        {...extra}
        variant='outlined'
        size='small'
        id={id}
        name={id}
        placeholder=''
        multiline
        maxRows='4'
        error={errorMessage.length > 0}
        helperText={errorMessage}
        inputProps={inputProps}
        InputProps={upperCaseInputProps}
        InputLabelProps={inputLabelProps}
      />
    </FormControl>
  )
}

TextAreaQuestion.propTypes = {
  errorMessage: PropTypes.string,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    limit: PropTypes.number,
    question: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired
  }).isRequired
}
