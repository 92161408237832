// PACKAGES
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import qs from 'query-string'
// UI
import { Box, Card, CardContent, CardActions, Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/WarningTwoTone'
// COMPONENTS
import StatusLabel from '../Styled/StatusLabel'
// UTILS
import { isShowApplicationComplete, linkTextForApplicationRow, statusForApplicationRow } from '../../util/status'

const ApplicationCard = styled(Card)`
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
`

const AppplicationContent = styled(CardContent)`
  display: flex;
  justify-content: space-between;
`

const ApplicationContentPosition = styled(Typography)`
  font-weight: 600;
  font-size: 1em;
`
const ApplicationContentInfo = styled(Box)`
  font-size: 0.9375em;
  font-weight: 400;
  line-height: 20px;
  color: #00000099;
`

const IncompleteIcon = styled(WarningIcon)`
  color: orange;
`

const ViewApplicationLink = styled.a`
  color: #2A5DB7;
  font-weight: 500;
  font-size: 0.75em;
`

const MobileApplicationCard = ({ application, token }) => {
  const { t } = useTranslation()

  return (
    <ApplicationCard data-testid='mobile-application-card'>
      <AppplicationContent>
        <div>
          <ApplicationContentPosition>{application.positionTitle}</ApplicationContentPosition>
          <ApplicationContentInfo>
            {application.brand} <br />
            {`${application.street} ${application.city}, ${application.state} ${application.zipCode}`} <br />
            {t('date-applied') + ' ' + new Date(Date.parse(`${application.appliedDate.split(/\s+/)[0]}T12:00:00.000Z`)).toLocaleDateString()}
          </ApplicationContentInfo>
        </div>
        <StatusLabel status={statusForApplicationRow(application)} />
      </AppplicationContent>
      <CardActions>
        <ViewApplicationLink href={qs.stringifyUrl({
          url: qs.parseUrl(application.viewApplication).url,
          query: { token: application.token }
        })}
        >
          {t(linkTextForApplicationRow(application))}
        </ViewApplicationLink>
        {isShowApplicationComplete(application) ? null : <IncompleteIcon />}
      </CardActions>
    </ApplicationCard>
  )
}

const ApplicationsTableMobile = ({ applications }) => {
  const { t } = useTranslation()

  return (
    <Box>{applications.length > 0
      ? applications.map(application => <MobileApplicationCard application={application} key={application.viewApplication} />)
      : <div style={{ textAlign: 'center' }}>{t('applications-not-found')}</div>}
    </Box>
  )
}

export default ApplicationsTableMobile
