// PACKAGES
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useContext } from 'use-context-selector'
import styled from 'styled-components'
// UI
import { Grid, Container, CircularProgress } from '@material-ui/core'
// COMPONENTS
import ApplicationCompleteCards from './ApplicationCompleteCards'
import ApplicationIncompleteCard from './ApplicationIncompleteCard'
import TrackingPixel from '../Utility/TrackingPixel'
// CONTEXTS
import { AnswerContext } from '../../contexts/AnswerContext'
import { StepsContext } from '../../contexts/StepsContext'
// CONFIG
import { legacyApplyURL } from '../../config'
// CONSTS
import { TRACKING_FINISH, TRACKING_SUBMIT } from '../../util/consts'
// SERVICES
import PostingsService from '../../services/postings'
import ProfileService from '../../services/profile'
import AdminFeatureService from '../../services/adminFeature'

const SuccessGrid = styled(Grid)`
  min-height: 100vh;
  text-align: center;
`

const Success = ({ postingDetails: { clientId, clientName, location: { id: locationId }, url }, postingId }) => {
  const [isLoading, setIsLoading] = useState(false)

  const [answerContext] = useContext(AnswerContext)
  const [stepsContext, stepsDispatch] = useContext(StepsContext)

  const history = useHistory()
  const location = useLocation()

  const getProfileValue = key => answerContext[`profile-${key}`] ? answerContext[`profile-${key}`].value : null

  const email = getProfileValue('email')
  const phone = getProfileValue('phone')
  const username = email || phone || ''

  const proceedToAdditionalSteps = async () => {
    // Redirect to complete WOTC if WOTC available, check if feature is enabled before redirecting
    if (stepsContext.wotc && !stepsContext.wotc.isComplete) {
      const isWOTCV2 = await AdminFeatureService.isFeatureEnabled(clientId, 'WotcV2')
      if (isWOTCV2) {
        return history.push(`/postings/${postingId}/application/additional-steps${location.search}`)
      }
    }
    // Redirect to legacy otherwise
    const path = ProfileService.parseApplyUrl(url)
    window.location.replace(legacyApplyURL + path + location.search)
  }

  useEffect(() => {
    if (!Object.keys(stepsContext).length) {
      setIsLoading(true)
      PostingsService.getAdditionalSteps(clientId, locationId, postingId).then(
        response => {
          response.forEach(additionalStep => {
            stepsDispatch({
              id: additionalStep.key,
              value: {
                label: additionalStep.label,
                description: additionalStep.description,
                timeEstimate: additionalStep.timeEstimate,
                isComplete: false
              }
            })
          })
          setIsLoading(false)
        }
      )
    }
  }, [clientId, locationId, postingId, stepsContext, stepsDispatch])

  const renderSuccessCards = (additionalSteps) => {
    return additionalSteps.length
      ? <ApplicationIncompleteCard clientName={clientName} nextSteps={additionalSteps} onComplete={proceedToAdditionalSteps} />
      : <ApplicationCompleteCards clientName={clientName} username={username} />
  }

  const noAdditionalSteps = !Object.keys(stepsContext).length

  return (
    <>
      <Container maxWidth='sm'>
        <SuccessGrid
          container
          direction='column'
          justifyContent='center'
          alignItems='stretch'
          spacing={4}
        >
          <Grid item xs={12} />
          {!isLoading
            ? renderSuccessCards(Object.keys(stepsContext).map(key => ({ ...stepsContext[key], key })))
            : <Grid item xs={12}><CircularProgress aria-label='Submitting Application' /></Grid>}
        </SuccessGrid>
      </Container>
      {noAdditionalSteps && <TrackingPixel eventName={TRACKING_FINISH} />}
      <TrackingPixel eventName={TRACKING_SUBMIT} />
    </>
  )
}

export default Success
