// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
// UI
import {
  FormControl,
  makeStyles,
  FormHelperText,
  MenuItem,
  Select,
  InputLabel
} from '@material-ui/core'

const sizeSmallPadding = '10.5px'

const useStyles = makeStyles(() => ({
  select: {
    paddingBottom: sizeSmallPadding,
    paddingTop: sizeSmallPadding
  }
}))

export default function DropdownQuestion ({
  errorMessage,
  question: {
    id,
    options,
    question,
    required
  },
  ...extra
}) {
  const classes = useStyles()
  const isError = !!errorMessage.length
  return (
    <FormControl
      margin='normal'
      variant='outlined'
      key={question.id}
      error={isError}
      size='small'
      fullWidth
      required={required}
    >
      <InputLabel size='small' id={id + '-label'} data-testid={id + '-label'}>
        {question}
      </InputLabel>
      <Select
        {...extra}
        labelId={id + '-label'}
        label={question}
        classes={{ root: classes.select }}
        id={id}
        name={id}
        variant='outlined'
      >
        {options.map(opt => (
          <MenuItem key={opt.value} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
      {isError && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

DropdownQuestion.propTypes = {
  errorMessage: PropTypes.string,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string
        ]),
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string
        ])
      })
    ).isRequired,
    question: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired
  }).isRequired
}
