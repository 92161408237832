// PACKAGES
import React from 'react'
// UI
import { Grid } from '@material-ui/core'
import WotcStep from './WotcStep'

const AdditionalStepsProvider = ({ postingDetails: { url } }) => {
  return (
    <Grid container justifyContent='center' alignContent='center'>
      <WotcStep actSection='disclaimer' redirect={url} />
    </Grid>
  )
}

export default AdditionalStepsProvider
