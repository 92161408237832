// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// UI
import { Typography } from '@material-ui/core'

const AnswerLabel = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`

const SelectAnswer = ({ question, answer }) => {
  const noAnswer = '--'

  const getAnswerLabel = question => {
    const answerElement = question.options.find(el => el.value === answer)
    if (answerElement) {
      return question.options.length > 1 ? answerElement.label : 'I agree'
    }
    return noAnswer
  }

  return <AnswerLabel>{getAnswerLabel(question)}</AnswerLabel>
}

SelectAnswer.propTypes = {
  answer: PropTypes.string.isRequired,
  question: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired
      })
    )
  }).isRequired
}

export default SelectAnswer
