import http from './httpClient'
import { bffURL } from '../config'

export default class AdminFeature {
  static isFeatureEnabled (clientId, feature) {
    return http.get(
            `${bffURL}/clients/${clientId}/adminfeature?feature=${feature}`
    )
  }
}
