// PACKAGES
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
// UI
import { Typography } from '@material-ui/core'
// SERVICES
import PostingService from '../../services/postings'

const CommentBox = styled.div`
  margin-bottom: 40px;
  padding: 0 20px;
`

const ConfirmationHeader = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(85, 85, 85, 0.75);
  margin-bottom: 20px;
`

const ConfirmationTypography = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #000000;
`

const ConfirmResponses = ({ postingId }) => {
  const [confirmationText, setConfirmationText] = useState('')

  useEffect(() => {
    PostingService.getConfirmationText(postingId).then(response => {
      if (response.data.disclosure) {
        setConfirmationText(response.data.disclosure)
      }
    })
  }, [postingId])

  return (
    confirmationText.length > 0 && (
      <CommentBox>
        <ConfirmationHeader>Confirm Responses</ConfirmationHeader>
        <ConfirmationTypography
          dangerouslySetInnerHTML={{ __html: confirmationText }}
        />
      </CommentBox>
    )
  )
}

export default ConfirmResponses
