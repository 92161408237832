// PACKAGES
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import parsePhoneNumber from 'libphonenumber-js'
// UI
import { Box, Button, CircularProgress, Collapse, FormControl, TextField, Typography } from '@material-ui/core'
import { Alert, ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import PhoneTextField from './Masks/PhoneTextField'
// SERVICES
import ApplicationsService from '../services/applications'
import Validation from '../services/validation'
import { recoverPhone } from '../config'
const StandardAlert = styled(Alert)`
  margin: 10px;
  max-width: 400px;
`

const RecoveryTitle = styled(Typography)`
  font-size: 1.5em;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.7px;
  text-align: center;
`

const RecoverySubtitle = styled(Typography)`
  color: #303030;
  font-size: 0.8em;
  text-align: center;
`

const RecoveryContent = styled(Box)`
  max-width: 397px;
  margin: 10px 0px 50px 0px;

  > * > * {
    margin: 5px;
  }
`

const ContactTypeButton = styled(ToggleButton)`{
  flex: 1;
  height: 32px;

  &.Mui-selected {
    background-color: #3f51b5;
    color: #FFF;
  }
  &.Mui-selected:hover {
    background-color: #303f9f;
    color: #FFF;
  }
}
`

const ApplicationRecovery = () => {
  const { t } = useTranslation()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [contactType, setContactType] = useState('email')
  const [contact, setContact] = useState('')
  const [recoveryMsg, setRecoveryMsg] = useState('')
  const [error, setError] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const handleFirstNameChange = useCallback(({ target: { value } }) => {
    setFirstName(value)
  }, [setFirstName])

  const handleLastNameChange = useCallback(({ target: { value } }) => {
    setLastName(value)
  }, [setLastName])

  const handleContactTypeChange = (_, newContactType) => {
    if (newContactType && newContactType !== contactType) {
      setContact('')
      setContactType(newContactType)
      setRecoveryMsg('')
    }
  }

  const handleContactChange = useCallback(({ target: { value } }) => {
    setContact(value)
  }, [setContact])

  const handleValidateContact = () => {
    if (contactType === 'email' && !Validation.validateEmail(contact)) {
      setError(true)
      setRecoveryMsg(t('recovery-email-bad'))
      return false
    } else if (contactType === 'phone' && !Validation.validatePhone(contact)) {
      setError(true)
      setRecoveryMsg(t('recovery-phone-bad'))
      return false
    }

    setError(false)
    setRecoveryMsg('')
    return true
  }

  const sendApplicationRecoveryRequest = async () => {
    if (!handleValidateContact()) return

    setIsSending(true)

    let parsedContact = contact
    if (contactType === 'phone') parsedContact = parsePhoneNumber(contact, 'US').nationalNumber
    try {
      await ApplicationsService.requestApplicationRecovery(parsedContact, contactType)
      setRecoveryMsg(contactType === 'email' ? <Trans i18nKey='recovery-email-success'>X<a href='https://mitratech.my.site.com/talentreef/s/article/Application-Access-Email-Delivery-Troubleshooting-Steps'>Y</a></Trans> : t('recovery-sms-success'))
    } catch (e) {
      setError(true)
      setRecoveryMsg(t('recovery-error'))
    } finally {
      setIsSending(false)
    }
  }

  return (
    <>
      <Collapse in={!!recoveryMsg}>
        <StandardAlert severity={error ? 'error' : 'success'}>{recoveryMsg}</StandardAlert>
      </Collapse>
      <RecoveryContent>
        <Box>
          <RecoveryTitle>{t('recovery-heading')}</RecoveryTitle>
          <RecoverySubtitle>{t('recovery-subtitle')}</RecoverySubtitle>
          <StandardAlert severity='info'>{t('recovery-disclaimer')}</StandardAlert>
        </Box>
        <FormControl fullWidth component='fieldset' margin='normal'>
          <TextField
            variant='outlined'
            size='small'
            label={`${t('recovery-first-name')}*`}
            placeholder=''
            onChange={handleFirstNameChange}
            value={firstName}
            error={false}
            inputProps={{ maxLength: 50 }}
          />
          <TextField
            variant='outlined'
            size='small'
            label={`${t('recovery-last-name')}*`}
            placeholder=''
            onChange={handleLastNameChange}
            value={lastName}
            error={false}
            inputProps={{ maxLength: 50 }}
          />
          {recoverPhone
            ? (
              <ToggleButtonGroup exclusive value={contactType} onChange={handleContactTypeChange}>
                <ContactTypeButton value='email'>{t('recovery-email')}</ContactTypeButton>
                <ContactTypeButton value='phone'>{t('recovery-phone')}</ContactTypeButton>
              </ToggleButtonGroup>
              )
            : null}
          <TextField
            variant='outlined'
            size='small'
            InputLabelProps={contactType === 'email' ? {} : { shrink: true }}
            inputProps={{ maxLength: 50 }}
            InputProps={contactType === 'email' ? {} : { inputComponent: PhoneTextField }}
            label={contactType === 'email' ? `${t('recovery-email')}*` : `${t('recovery-phone')}*`}
            placeholder=''
            onChange={handleContactChange}
            onBlur={handleValidateContact}
            value={contact}
            error={false}
          />
        </FormControl>
        <Button
          onClick={sendApplicationRecoveryRequest}
          type='submit'
          name='verify code'
          size='large'
          variant='contained'
          color='primary'
          disabled={!(firstName && lastName && contact) || isSending}
          style={{ width: '100%' }}
        >
          {isSending ? <>{t('recovery-processing')}<CircularProgress size='1rem' /></> : t('recovery-submit')}
        </Button>
      </RecoveryContent>
    </>
  )
}

export default ApplicationRecovery
