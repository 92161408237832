// PACKAGES
import jwt_decode from 'jwt-decode' // eslint-disable-line
import Cookies from 'js-cookie/src/js.cookie'
import * as psl from 'psl'
// UTILS
import { ARETE_TOKEN, COGNITO_ID_TOKEN } from '../util/consts'

const getDomain = () => {
  return psl.parse(window.location.hostname).domain
}

export default class Jwt {
  static getIdToken () {
    return Cookies.get(COGNITO_ID_TOKEN)
  }

  static removeIdToken () {
    Cookies.remove(COGNITO_ID_TOKEN, { domain: getDomain() })
  }

  static removeAreteToken () {
    Cookies.remove(ARETE_TOKEN, { domain: getDomain() })
  }

  static userProfile (idToken) {
    if (idToken) {
      const jwt = jwt_decode(idToken)
      return Promise.resolve({
        cognitoId: jwt['cognito:username'],
        firstName: jwt.given_name,
        lastName: jwt.family_name,
        ...(jwt.email !== undefined && { email: jwt.email }),
        ...(jwt.phone_number !== undefined && { phone: jwt.phone_number })
      })
    } else {
      return Promise.reject(new Error('invalid token'))
    }
  }
}
