export default class SectionService {
  static getNextSection (allSections, currentSectionName) {
    const sectionIdx = allSections.findIndex(
      section => section.name === currentSectionName
    )
    if (sectionIdx === -1 || sectionIdx + 1 >= allSections.length) {
      return null
    }
    return allSections[sectionIdx + 1].name
  }
}
