// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
// UI
import { TextField, FormControl, InputAdornment } from '@material-ui/core'
// COMPONENTS
import PhoneTextField from '../Masks/PhoneTextField'
import ZipPostalCodeTextField from '../Masks/ZipPostalCodeTextField'
export default function TextQuestion ({
  question: {
    id,
    limit,
    question,
    required,
    validation
  },
  errorMessage,
  ...extra
}) {
  const inputProps = {}
  const inputLabelProps = {}
  const upperCaseInputProps = {}

  if (validation === 'phone-number') {
    upperCaseInputProps.inputComponent = PhoneTextField
    upperCaseInputProps.startAdornment = <InputAdornment position='start'>+1</InputAdornment>
    inputLabelProps.shrink = true
  } else if (validation === 'zip') {
    upperCaseInputProps.inputComponent = ZipPostalCodeTextField
    inputLabelProps.shrink = true
  } else if (validation === 'ssn') {
    inputLabelProps.shrink = true
    inputProps.type = 'password'
    inputProps.maxLength = limit
  } else {
    inputProps.maxLength = limit
  }
  return (
    <FormControl fullWidth component='fieldset' margin='normal'>
      <TextField
        {...extra}
        variant='outlined'
        size='small'
        required={required}
        id={id}
        name={id}
        label={question}
        placeholder=''
        fullWidth
        error={errorMessage.length > 0}
        helperText={errorMessage}
        inputProps={inputProps}
        InputProps={upperCaseInputProps}
        InputLabelProps={inputLabelProps}
      />
    </FormControl>
  )
}

TextQuestion.propTypes = {
  errorMessage: PropTypes.string,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    limit: PropTypes.number,
    question: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    validation: PropTypes.string
  }).isRequired
}
