// PACKAGES
import React, { forwardRef, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useContext } from 'use-context-selector'
import { useTranslation } from 'react-i18next'
// UI
import { Button, Typography } from '@material-ui/core'
import { Close, FileCopyOutlined } from '@material-ui/icons'
// CONTEXTS
import { FileContext } from '../../contexts/FileContext'

const HiddenInput = styled.input`
  display: none;
`

const StyledList = styled.ul`
  padding-left: 0;

  li {
    align-items: center;
    display: flex;
    list-style: none;
    padding-top: .5em;

    &:last-of-type {
      padding-bottom: 1.5em;
    }

    span {
      padding-left: .75em;

      * {
        font-size: .88em
      }

      ${props => props.handleDelete && `
        &:first-of-type {
          cursor: pointer;
          padding-left: 0;

          * {
            align-items: center;
            display: flex;
            font-size: .66em;

            &:hover {
              color: theme.palette.primary.main;
            }
          }
        }
      `}
    }
  }
`

const StyledButton = styled(Button)`
  margin-bottom: 23px;
`

const acceptedFileTypes = [
  '.doc',
  '.docx',
  '.pdf',
  '.png',
  '.jpg',
  '.jpeg',
  '.heic'
].join(',')

const allowedExtensions = /(\.doc|\.docx|\.pdf|\.png|\.jpg|\.jpeg|\.heic)$/i

export const FilesList = ({ ctx, handleDelete }) => (
  <StyledList>
    {Object.entries(ctx).map(([id, { name }]) => (
      <li data-testid={id} key={id}>
        {handleDelete && (
          <span data-testid='close-btn' onClick={() => { handleDelete(id) }}>
            <Close />
          </span>
        )}
        <span><FileCopyOutlined /></span>
        <span>{name}</span>
      </li>
    ))}
  </StyledList>
)

FilesList.propTypes = {
  ctx: PropTypes.object.isRequired,
  handleDelete: PropTypes.func
}

const FileUploader = forwardRef(({ handleChange, required }, ref) => {
  const { t } = useTranslation()
  const hiddenInput = useRef()
  const helperTextRef = useRef()
  const [context, dispatch] = useContext(FileContext)

  const handleClick = () => {
    hiddenInput.current.click()
  }

  const handleUpload = ({ target: { files } }) => {
    const file = files[0]
    const id = `${file.name}-${file.lastModified}`

    // Double check file type
    if (!allowedExtensions.exec(file.name)) {
      helperTextRef.current.scrollIntoView({ behavior: 'smooth' })
      helperTextRef.current.style.color = 'red'
      return
    }
    // Return to text to default color if upload is succesful.
    helperTextRef.current.style.color = 'rgba(0, 0, 0, 0.54)'

    dispatch({ id, file })
    // allow change handler to be fired again by unsetting ref
    hiddenInput.current.value = null
    // change parent text to non-error state
    handleChange()
  }

  const handleDelete = id => {
    // undefined val for `file` key allows reducer to delete file entry
    dispatch({ id })
  }

  return (
    <div ref={ref}>
      <StyledButton
        color='primary'
        data-testid='upload-btn'
        onClick={handleClick}
        variant='outlined'
      >
        {t('upload')}
      </StyledButton>
      <HiddenInput
        accept={acceptedFileTypes}
        data-testid='file-input'
        name='file'
        onChange={handleUpload}
        ref={hiddenInput}
        type='file'
      />
      <Typography variant='body2' color='textSecondary' ref={helperTextRef}>
        {t('resume-filetype-helpertext')}
      </Typography>
      <FilesList ctx={context} handleDelete={handleDelete} />
    </div>
  )
})

FileUploader.propTypes = {
  handleChange: PropTypes.func.isRequired
}

export default FileUploader
