import { Grid, Typography, styled } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

const OnboardingWotcCompleted = () => {
  const { t } = useTranslation()

  const WotcDescription = styled(Typography)(({ theme }) => ({
    lineHeight: '1.5rem',
    textAlign: 'center'

  }))

  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item lg={8} xs={10}>
          <WotcDescription variant='subtitle1'>{t('wotc-onboarding-redirect-message')}</WotcDescription>
        </Grid>
      </Grid>
    </>
  )
}

export default OnboardingWotcCompleted
