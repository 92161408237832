import http from './httpClient'
import { bffURL } from '../config'
import Jwt from '../services/jwt'
import { transformQueryString } from '../util/util'

export default class UploadAnswers {
  static async post (answerJson) {
    const idToken = Jwt.getIdToken()

    const transformedQS = transformQueryString(window.location.search)

    if (idToken) {
      try {
        return await http.post(`${bffURL}/auth/applications${transformedQS}`, answerJson, idToken)
      } catch (e) {
        if (e?.response?.status === 401) {
          return await http.post(`${bffURL}/applications${transformedQS}`, answerJson).catch(e => (console.warn(`Failed to fetch: ${e}`)))
        } else {
          console.warn(`Failed to post auth application: ${e}`)
        }
      }
    } else {
      return await http.post(`${bffURL}/applications${transformedQS}`, answerJson).catch(e => (console.warn(`Failed to post anonymous application: ${e}`)))
    }
  }
}
