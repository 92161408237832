import Applications from '../http/applications'
import Jwt from './jwt'

export default class ApplicationsService {
  static async getApplicationsWithToken (token) {
    const idToken = Jwt.getIdToken()
    const response = await Applications.getApplicationsWithToken(token, idToken)
    return response.data
  }

  static async getApplicationWithToken (token) {
    const idToken = Jwt.getIdToken()
    const response = await Applications.getApplicationWithToken(token, idToken)
    return response.data
  }

  static async getApplicationsWithProfileId () {
    const idToken = Jwt.getIdToken()
    const response = await Applications.getApplicationsWithProfileId(idToken)
    return response.data
  }

  static async requestApplicationRecovery (contact, contactType) {
    const idToken = Jwt.getIdToken()
    await Applications.requestApplicationRecovery(contact, contactType, idToken)
  }
}
