// PACKAGES
import React, { useReducer } from 'react'
import { createContext } from 'use-context-selector'

export const AppContext = createContext()

export const AppProvider = ({ children, initialState = {} }) => {
  const reducer = (state, action) => {
    return { ...state, [action.id]: action.value }
  }

  const value = useReducer(reducer, initialState)

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  )
}
