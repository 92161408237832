// PACKAGES
import PropTypes from 'prop-types'
// HOOKS
import { useTrackingIntegration } from '../../util/hooks'

// For information about how we use pixel tracking at talentReef, look at:
// https://jobapp.atlassian.net/wiki/spaces/API/pages/2471493878/Implementing+Pixel+Tracking+on+the+Front-end+-+TR+Apply

const TrackingPixel = ({ eventName }) => {
  useTrackingIntegration(eventName)
  return null
}

TrackingPixel.propTypes = {
  eventName: PropTypes.string
}

export default TrackingPixel
