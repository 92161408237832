// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// UI
import { Typography } from '@material-ui/core'

const AnswerLabel = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`

const MultiSelectAnswer = ({ question, answer }) => {
  if (!answer?.values?.length) {
    return <AnswerLabel>--</AnswerLabel>
  }

  const getAnswerLabel = val => {
    const answerElement = question.options.find(q => q.value === val)
    return answerElement.label
  }

  return (
    <div>
      {answer.values.map(val => (
        <AnswerLabel key={val}>{getAnswerLabel(val)}</AnswerLabel>
      ))}
    </div>
  )
}

MultiSelectAnswer.propTypes = {
  answer: PropTypes.shape({
    values: PropTypes.arrayOf(
      PropTypes.string.isRequired
    )
  }).isRequired,
  question: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    )
  }).isRequired
}

export default MultiSelectAnswer
