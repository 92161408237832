// SERVICES
import http from './httpClient'
import Jwt from '../services/jwt'
// CONFIG
import { bffURL } from '../config'
// UTILS
import { OWNER_TYPE_APPLICANT, OWNER_TYPE_APPLICATION } from '../util/consts'

export default class UploadFile {
  static async post (
    {
      applicationId,
      profileId
    },
    file,
    docTypeId
  ) {
    // use multipart/form-data encoding for payload
    const formData = new window.FormData()
    // default to docTypeId for 'other' for now
    formData.append('docTypeId', docTypeId || 4)
    // the binary data for the uploaded file
    formData.append('file', file)
    formData.append('name', file.name)

    // use the random UUID affiliated with application if user is anonymous
    const ownerId = profileId || applicationId
    const ownerType = profileId ? OWNER_TYPE_APPLICANT : OWNER_TYPE_APPLICATION

    const idToken = Jwt.getIdToken()
    if (idToken && ownerType === OWNER_TYPE_APPLICANT) {
      return await http.post(`${bffURL}/auth/owners/${ownerId}/documents-file?ownerType=${ownerType}`, formData, idToken)
    }
    return await http.post(`${bffURL}/owners/${ownerId}/documents-file?ownerType=${ownerType}`, formData)
  }
}
