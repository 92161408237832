// PACKAGES
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useContext } from 'use-context-selector'
import { useParams } from 'react-router-dom'
import { useTranslation, getI18n } from 'react-i18next'
import styled from 'styled-components'
// UI
import {
  AppBar,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Toolbar,
  useMediaQuery
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { StylesProvider } from '@material-ui/core/styles'
// CONFIG
import { imagesURL, authURL, jobDescriptionURL, legacyApplyURL } from '../config'
// SERVICES
import Jwt from '../services/jwt'
import Postings from '../services/postings'
import Redirect from '../services/redirect'
// UTILS
import device from '../util/device'
// CONTEXTS
import { AppContext } from '../contexts/AppContext'
// COMPONENTS
import LanguageSelect from '../components/Layout/LanguageSelect'
import AdminFeatureService from '../services/adminFeature'

const FlexContainer = styled.div`
  display: grid;
  height: 100%;
  align-items: stretch;
  grid-template-rows: [header] min-content [content] auto [footer] min-content [end];
`

const TrToolbar = styled(Toolbar)`
  grid-row-start: header;
   {
    background-color: #f4f4f4;
    .logo-icon {
      width: 80px;
    }
  }
`

const TrContentArea = styled.div`
  grid-row-start: content;
  flex: 1 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
`

const NavUserName = styled.div`
  margin-right: 16px;
  color: black;
  text-transform: uppercase;
  letter-space: 2px;

  @media ${device.desktops} {
    display: none;
  }
`

const FooterArea = styled.div`
  background-color: #121C37;
  box-sizing: border-box;
  color: white;
  display: flex;
  grid-row-start: footer;
  justify-content: flex-start;
  padding-bottom: 40px;
  padding-top: 40px;
  width: 100vw;

  @media ${device.tablets} {
    padding-left: 20px;
  }
`

const FooterLogo = styled.img`
  width: 94px;

  @media ${device.desktops} {
    padding-left: 138px;
  }

  @media ${device.tablets} {
    margin: auto;
    display: block;
  }
`

const FooterGrid = styled(Grid)`
  max-width: fit-content;
  padding-left: 50px;
  padding-bottom: 20px;
  padding-right: 50px;
`

const FooterLabel = styled(Typography)`
  font-size: small;
`

const CopyrightLink = styled.a`
  color: #ffffff;
  font-size: x-small;
  font-weight: 400;
  padding-right: 20px;
`

const FooterLink = styled.a`
  color: #ffffff;
  font-size: small;
  font-weight: 400;
`

const SplashLayout = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [isMagicLink, setIsMagicLink] = useState(false)
  const [appState, appDispatch] = useContext(AppContext)
  const { postingId } = useParams()
  const { t } = useTranslation()

  const isMobile = useMediaQuery(device.tablets)

  useEffect(() => {
    (async () => {
      try {
        if (postingId !== undefined) {
          const posting = await Postings.getPosting(postingId)
          const { clientId } = posting
          const magicLink = await AdminFeatureService.isFeatureEnabled(clientId, 'ApplicationMagicLink')
          setIsMagicLink(magicLink)
        }
      } catch (error) {}
    })()
    const idToken = Jwt.getIdToken()
    Jwt.userProfile(idToken)
      .then(userProfile => {
        appDispatch({
          id: 'user',
          value: userProfile
        })
      })
      .catch(() => {})
  }, [appDispatch, postingId, isMagicLink])

  const redirectToCreateAccount = () => {
    const hostOrigin = window.location.origin
    const returnUrl = `${hostOrigin}${window.location.pathname}`
    window.location.replace(`${authURL}/sign-up?lng=${getI18n().language}&redirect=${hostOrigin}?redirect=${returnUrl}`)
  }

  const signOutUser = async () => {
    Jwt.removeIdToken()
    Jwt.removeAreteToken()

    if (postingId === undefined) {
      const legacySignOutPage = `${legacyApplyURL}/apply/logout.cfm?redirect=${window.location.href}`
      Redirect.navigate(legacySignOutPage)
    } else {
      const posting = await Postings.getPosting(postingId)
      const jobDescriptionPage = `${jobDescriptionURL}/clients/${posting.clientId}/posting/${postingId}`
      const legacySignOutPage = `${legacyApplyURL}/apply/logout.cfm?redirect=${jobDescriptionPage}`
      Redirect.navigate(legacySignOutPage)
    }
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // TODO material-ui menu components give warnings with Strict Mode
  // Upgrade to material-ui v5 once it is released
  return (
    <StylesProvider injectFirst>
      <FlexContainer>
        <AppBar position='static' elevation={0}>
          <TrToolbar variant='dense'>
            <Grid container spacing={4} justifyContent='flex-end' alignItems='center'>
              <Grid item>
                <img
                  className='logo-icon'
                  alt='talentReef'
                  src={imagesURL + '/logo/powered-by/gray-color-462x222.png'}
                />
              </Grid>
              {isMagicLink || window.location.pathname.includes('/applicant/applications')
                ? <></>
                : (appState.user
                    ? (
                      <>
                        <Grid item>
                          <IconButton
                            data-testid='menu-icon'
                            aria-controls='simple-menu'
                            aria-haspopup='true'
                            onClick={handleClick}
                          >
                            <MenuIcon color='primary' />
                          </IconButton>
                          <Menu
                            id='simple-menu'
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={signOutUser}>Sign Out</MenuItem>
                          </Menu>
                        </Grid>
                        <NavUserName item>
                          <Grid>
                            <p>{appState.user.firstName}</p>
                          </Grid>
                        </NavUserName>
                      </>
                      )
                    : (
                      <Grid item>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={() => redirectToCreateAccount()}
                        >
                          {t('sign-up-button')}
                        </Button>
                      </Grid>
                      )
                  )}
            </Grid>
          </TrToolbar>
        </AppBar>
        <TrContentArea>{children}</TrContentArea>
        <FooterArea>
          <Grid container justifyContent='flex-start' alignItems='flex-start'>
            <Grid item xs={12} sm={4} style={{ maxWidth: !isMobile ? 'fit-content' : null }}>
              <FooterLogo
                alt='talentReef'
                src={imagesURL + '/logo/white-1000x476.png'}
              />
            </Grid>
            <FooterGrid item>
              <LanguageSelect />
            </FooterGrid>
            <FooterGrid item xs={12} sm={4}>
              <FooterLabel align='left' style={{ color: '#b5d443' }}>{t('footer-contact')}</FooterLabel>
              <Typography align='left'>
                <FooterLink href='http://mitratech.force.com/applicanthelp/s/'>
                  {t('footer-support')}
                </FooterLink>
              </Typography>
            </FooterGrid>
            <Grid item xs={12} sm={4}>
              <FooterLabel align='left'>
                {t('footer-copyright', { year: new Date().getFullYear() })}
              </FooterLabel>
              <Grid container>
                <Typography>
                  <CopyrightLink href='https://www.talentreef.com/privacy-policy/' target='_blank' rel='noopener noreferrer'>{t('footer-privacy')}</CopyrightLink>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </FooterArea>
      </FlexContainer>
    </StylesProvider>
  )
}

SplashLayout.propTypes = {
  children: PropTypes.any
}

export default SplashLayout
