// PACKAGES
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// UI
import { FormControl, FormHelperText, FormLabel, Checkbox, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  check: {
    paddingLeft: '0px'
  }
}))

const SubheadingLabel = styled(FormLabel)`
  font-size: 0.8rem;
  color: #3c3c3c;
  margin: 0;
  padding-left: 33px;
`

const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  color: rgb(0,0,0);
  margin: 0 0 1em 0;
`

export default function YesNoQuestion ({
  errorMessage,
  handleCheckAnswer,
  question: {
    id,
    question,
    helperText
  },
  value,
  ...extra
}) {
  const isError = !!errorMessage.length

  useEffect(() => {
    handleCheckAnswer({ target: { checked: value === '1' } })
  }, [handleCheckAnswer, value])

  return (
    <FormControl component='fieldset' margin='normal' error={isError}>
      <QuestionContainer>
        <Checkbox
          {...extra}
          classes={{ root: useStyles().check }}
          color='primary'
          data-testid='yes-no-question-checkbox'
          id={id}
          name={id}
          onChange={handleCheckAnswer}
          checked={value === '1'}
        />
        <span dangerouslySetInnerHTML={{ __html: question }} />
      </QuestionContainer>
      {isError && <FormHelperText>{errorMessage}</FormHelperText>}
      {helperText && <SubheadingLabel>{helperText}</SubheadingLabel>}
    </FormControl>
  )
}

YesNoQuestion.propTypes = {
  errorMessage: PropTypes.string,
  handleCheckAnswer: PropTypes.func.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired
  }).isRequired,
  value: PropTypes.string
}
