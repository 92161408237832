import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const StyledMessage = styled(Typography)`
   {
    width: 100%;
    padding-bottom: 1vh;
    text-align: center;
  }
`

const Message = props => {
  return <StyledMessage>{props.children}</StyledMessage>
}

export default Message
