// PACKAGES
import React from 'react'
import { useContext } from 'use-context-selector'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
// UI
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup
} from '@material-ui/core'
// CONTEXTS
import { AnswerContext } from '../../contexts/AnswerContext'
// COMPONENTS
import QuestionFormLabel from '../Styled/QuestionFormLabel'

export default function PreferenceQuestion ({ question: { question, id, numberOfPreferences, required, options }, handleChangeById }) {
  const [answerContext] = useContext(AnswerContext)
  const { t } = useTranslation()

  const handlePreferenceChange = id => e => { handleChangeById(id, e.target.value) }

  return (
    <>
      <QuestionFormLabel required={required}>
        <span dangerouslySetInnerHTML={{ __html: question }} />
      </QuestionFormLabel>
      <Grid container spacing={1}>
        {Array(numberOfPreferences).fill(1).map((x, preferenceNo) => {
          preferenceNo++
          const preferenceId = id + preferenceNo
          const preferenceError = answerContext[preferenceId]?.errorMessage
          const defaultValue = answerContext[preferenceId]?.value || ''
          return (
            <Grid key={preferenceId} item xs={12}>
              <FormControl component='fieldset' margin='normal' error={!!preferenceError}>
                <QuestionFormLabel>
                  {t('preference')} {preferenceNo}: {preferenceNo > 1 && `(${t('optional')})`}
                </QuestionFormLabel>
                <RadioGroup
                  value={defaultValue}
                  onChange={handlePreferenceChange(preferenceId)}
                  id={preferenceId}
                  name={preferenceId}
                >
                  {options.map(opt => {
                    return (
                      <FormControlLabel
                        data-testid={`${preferenceId}-q-op-${opt.value}`}
                        key={opt.value} value={opt.value}
                        control={<Radio color='primary' />} label={opt.label}
                      />
                    )
                  })}
                </RadioGroup>
                {!!preferenceError && <FormHelperText data-testid='preference-q-error'>{preferenceError}</FormHelperText>}
              </FormControl>
            </Grid>
          )
        }
        )}
      </Grid>
    </>
  )
}

PreferenceQuestion.propTypes = {
  handleChangeById: PropTypes.func.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    numberOfPreferences: PropTypes.number.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string
        ]),
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string
        ])
      }).isRequired
    ).isRequired
  }).isRequired
}
