import http from './httpClient'
import { bffURL } from '../config'
import parsePhoneNumber from 'libphonenumber-js'

export default class Profile {
  static create (userProfile, idToken) {
    return call('create', userProfile, idToken)
  }

  static merge (userProfile, idToken) {
    return call('merge', userProfile, idToken)
  }

  static async get (cognitoId, idToken) {
    return http.get(`${bffURL}/auth/profiles/${cognitoId}`, idToken).catch(e => (console.warn(`Failed to fetch profile: ${e}`)))
  }

  static checkUserApplied (firstName, lastName, phone, email, jobId) {
    const parsedPhone = parsePhoneNumber(phone, 'US').nationalNumber
    return http.get(
      `${bffURL}/profiles/applied?firstName=${firstName}&lastName=${lastName}&phone=${parsedPhone}&email=${email}&jobId=${jobId}`
    ).catch(e => (console.warn(`Failed to check if user has applied: ${e}`)))
  }
}

function call (path, userProfile, idToken) {
  const api = process.env.REACT_APP_SESSION_SERVICE_URL
  return http.post(`${api}/profile/${path}`, userProfile, idToken).catch(e => (console.warn(`Failed to call profile: ${e}`)))
}
