const submittedOptions = {
  true: {
    status: 'completed-status',
    link: 'submitted-complete-link'
  },
  false: {
    status: 'submitted-incomplete-status',
    link: 'submitted-incomplete-link'
  }
}

const offerOptions = {
  true: {
    status: 'offer-pending-status',
    link: 'offer-pending-link'
  },
  false: {
    status: 'offer-pending-status',
    link: 'offer-pending-link'
  }

}

const onboardingOptions = {
  false: {
    status: 'incomplete-status',
    link: 'onboarding-incomplete-link'
  },
  true: {
    status: 'completed-status',
    link: 'onboarding-complete-link'
  }
}

const terminatedOptions = {
  status: 'rejected-status',
  link: 'click-here'
}

export const outputForApplication = (row) => {
  if (row.status === 'Hired') {
    return onboardingOptions[isShowApplicationComplete(row)]
  } else if (row.status === 'Applied') {
    return submittedOptions[row.isComplete]
  } else if (row.status === 'Offer') {
    return offerOptions[row.isComplete]
  } else if (row.status === 'Terminated' || row.status === 'Release') {
    return terminatedOptions
  } else {
    return submittedOptions[row.isComplete]
  }
}

export const isShowApplicationComplete = (row) => {
  if (row.status === 'Hired') {
    return row.isOnboardingComplete
  } else if (row.status === 'Release' || row.status === 'Terminated') {
    return true
  } else {
    return row.isComplete
  }
}

export const statusForApplicationRow = (row) => {
  const output = outputForApplication(row)
  const statusText = output?.status
  return statusText
}

export const linkTextForApplicationRow = (row) => {
  const output = outputForApplication(row)
  const linkText = output?.link
  return linkText
}
