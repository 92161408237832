// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
// UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery
} from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useTheme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'

const CenteredDialogContentText = styled(DialogContentText)`
  text-align: center;
`

function ErrorDialog ({ closeDialog, open, message }) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const errorMsg = message || t('error-try-again')
  return (
    <Dialog fullScreen={fullScreen} open={open} maxWidth='xs'>
      <ErrorOutlineIcon
        fontSize='large'
        style={{ color: red[900], alignSelf: 'center', paddingTop: '2rem' }}
      />
      <DialogContent>
        <DialogTitle>
          <div style={{ textAlign: 'center' }}>
            {t('error-application-submit')}
          </div>
        </DialogTitle>
        <CenteredDialogContentText>{errorMsg}</CenteredDialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button disableElevation variant='contained' style={{ marginBottom: '1rem' }} onClick={() => closeDialog()}>{t('close-button')}</Button>
      </DialogActions>
    </Dialog>
  )
}

ErrorDialog.propTypes = {
  closeDialog: PropTypes.func,
  open: PropTypes.bool
}

export default ErrorDialog
