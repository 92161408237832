import url from 'url'

export default class Redirect {
  static parseUrl () {
    try {
      const redirectUrl = url.parse(window.location.href, true).query.redirect // eslint-disable-line
      return new URL(redirectUrl).href
    } catch (err) {
      return undefined
    }
  }

  static navigate (url) {
    if (url) {
      window.location.assign(url)
    }
  }

  static getQueryParams (url) {
    const params = url.split('?')[1]
    return params ? `?${params}` : ''
  }
}
