// PACKAGES
import PropTypes from 'prop-types'
import React from 'react'
import { useContext } from 'use-context-selector'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
// UI
import { Button, Grid, LinearProgress, Menu, MenuItem } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
// CONTEXTS
import { SectionContext } from '../../contexts/SectionContext'
import { StepsContext } from '../../contexts/StepsContext'
// UTILS
import { ADDITIONAL_STEPS, REVIEW } from '../../util/consts'

const TabsMenuContainer = styled.div`
  text-align: center;
`

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  fill: #b5d443;
`

const ProgressButtonTypography = styled.span`
  letter-spacing: 1.5px;
  font-size: 10px;
  font-weight: 500;
`

const StyledUncheckedCircleIcon = styled(RadioButtonUncheckedIcon)`
  fill: #b5d443;
`

const StyledMenuItem = styled(MenuItem)`
  color: #3b62ae;
  justify-content: center;
  font-weight: 500;
`

const useStyles = makeStyles(theme => ({
  root: {
    color: '#3B62AE'
  },
  menu: {
    width: '100vw'
  },
  popoverPaper: {
    width: '95%',
    height: '60%',
    maxHeight: 'unset',
    paddingTop: '20px'
  }
}))

const BorderLinearProgress = withStyles(theme => ({
  root: {
    marginBottom: '10px'
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    backgroundColor: '#B5D443'
  }
}))(LinearProgress)

const ProgressBarMobile = ({ sectionName, sections }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [state] = useContext(SectionContext)
  const [additionalSteps] = useContext(StepsContext)
  const classes = useStyles()
  const { t } = useTranslation()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getBarValue = sectionName => {
    const sectionsTotal = sections.length + 1
    let completedSections = 0

    sections.forEach((section, index) => {
      if (state[section.name] && state[section.name].completed) {
        completedSections += 1
      }
    })
    return Math.floor((completedSections / sectionsTotal) * 100)
  }

  const sectionCompleteLogo = sectionName => {
    if (state[sectionName] && state[sectionName].completed) {
      return <StyledCheckCircleIcon />
    } else {
      return <StyledUncheckedCircleIcon />
    }
  }

  return (
    <TabsMenuContainer>
      <BorderLinearProgress
        variant='determinate'
        value={getBarValue(sectionName)}
      />
      <Button
        data-testid='pbm-btn'
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        classes={{ root: classes.root }}
      >
        <ProgressButtonTypography>
          Application Progress
        </ProgressButtonTypography>
      </Button>
      <Menu
        PopoverClasses={{ paper: classes.popoverPaper }}
        id='customized-menu'
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {sections.map(section => (
          <MenuItem key={section.name} onClick={handleClose}>
            <Grid
              container
              spacing={1}
              justifyContent='flex-start'
              alignItems='center'
            >
              <Grid item>{sectionCompleteLogo(section.name)}</Grid>
              <Grid item>{section.title?.toUpperCase()}</Grid>
            </Grid>
          </MenuItem>
        ))}
        <MenuItem onClick={handleClose}>
          <Grid container spacing={1} justifyContent='flex-start' alignItems='center'>
            <Grid item>{sectionCompleteLogo(REVIEW)}</Grid>
            <Grid item>{t('review')}</Grid>
          </Grid>
        </MenuItem>
        {Object.keys(additionalSteps).length > 0 && (
          <MenuItem onClick={handleClose}>
            <Grid container spacing={1} justifyContent='flex-start' alignItems='center'>
              <Grid item>{sectionCompleteLogo(ADDITIONAL_STEPS)}</Grid>
              <Grid item>{t('additional-steps')}</Grid>
            </Grid>
          </MenuItem>
        )}
        <StyledMenuItem onClick={handleClose}>CLOSE</StyledMenuItem>
      </Menu>
    </TabsMenuContainer>
  )
}

ProgressBarMobile.propTypes = {
  sectionName: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  )
}

export default ProgressBarMobile
