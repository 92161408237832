import { Button, Card, CardActions, CardContent, Grid, Typography, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const WotcDisclaimerGrid = styled(Grid)(({ theme }) => ({
  marginBottom: '3.75rem',
  marginTop: '3.75rem',

  [theme.breakpoints.down('xs')]: {
    marginBottom: '2.5rem',
    marginTop: '2.5rem'
  }
}))

const WotcCard = styled(Card)(({ theme }) => ({
  padding: '2.75rem',
  border: '1px solid var(--mui-gray-300, #E0E0E0)',

  [theme.breakpoints.down('xs')]: {
    padding: '1.38rem'
  }
}))

const WotcCardContent = styled(CardContent)({
  padding: '0'
})

const WotcHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: '600',
  lineHeight: '1.5rem',
  marginBottom: '0.75rem',
  color: '#000000',

  [theme.breakpoints.down('xs')]: {
    fontSize: '1.125rem',
    fontWeight: '500'
  }
}))

const WotcSubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  lineHeight: '1.5rem',
  marginBottom: '0.875rem',
  fontWeight: '500',
  color: '#000000',

  [theme.breakpoints.down('xs')]: {
    lineHeight: '1.25rem',
    fontSize: '0.875rem',
    fontWeight: '600',
    letterSpacing: '0.00625rem'
  }
}))

const WotcBody = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  lineHeight: '1.5rem',
  textAlign: 'justify',
  marginBottom: '1.5rem',
  color: '#000000',

  [theme.breakpoints.down('xs')]: {
    lineHeight: '1.25rem',
    fontSize: '0.875rem',
    fontWeight: '400',
    letterSpacing: '0.01063rem'
  }
}))

const WotCardActions = styled(CardActions)(({ theme }) => ({
  padding: '0',
  gap: '1.5rem',

  [theme.breakpoints.down('xs')]: {
    gap: '1.12rem'
  }
}))

const OutlinedButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  height: '2.5rem',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.25rem',
  flex: '1 0 0',
  border: '1px solid var(--secondary-main, #2A5DB7)',
  boxShadow: '0px 1px 25px 0px rgba(0, 0, 0, 0.05)',
  color: 'var(--secondary-main, #2A5DB7)',
  fontFamily: 'Inter',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  textTransform: 'none',
  padding: ' 0.5rem 0.75rem',
  letterSpacing: ' 0.02875rem',

  '&:hover': {
    background: 'var(--secondary-main, #F5F5F5)',
    color: 'var(--secondary-main, #2A5DB7)'
  },

  [theme.breakpoints.down('xs')]: {
    height: '2rem',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    padding: '0.375rem 0.5rem'
  }
}))

const ContainedButton = styled(OutlinedButton)({
  background: 'var(--secondary-main, #2A5DB7)',
  color: 'var(--primary-contrast, #FFF)',
  '&:hover': {
    background: 'var(--secondary-main, #0D1D38)',
    color: 'var(--primary-contrast, #FFF)'
  }
})

const WotcDisclaimer = (props) => {
  const { t } = useTranslation()

  return (
    <Grid container justifyContent='center'>
      <WotcDisclaimerGrid item lg={6} xs={10}>
        <WotcCard>
          <WotcCardContent style={{ padding: '0' }}>
            <WotcHeading variant='h6'>
              {t('wotc-title')}
            </WotcHeading>
            <WotcSubTitle variant='subtitle1'>
              {t('wotc-sub-title')}
            </WotcSubTitle>
            <WotcBody variant='subtitle1'>
              {t('wotc-description1')}
              <p />
              {t('wotc-description2')}
              <p />
            </WotcBody>
          </WotcCardContent>
          <WotCardActions>
            <ContainedButton onClick={() => props.handleNewSection('assessment')} aria-label='proceed-wotc'>{t('wotc-button-proceed')}</ContainedButton>
          </WotCardActions>
        </WotcCard>
      </WotcDisclaimerGrid>
    </Grid>
  )
}

export default WotcDisclaimer
