// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
import { useContext } from 'use-context-selector'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
// UI
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import { Tabs, Tab, makeStyles } from '@material-ui/core'
// CONTEXTS
import { SectionContext } from '../../contexts/SectionContext'
import { StepsContext } from '../../contexts/StepsContext'
// UTILS
import { ADDITIONAL_STEPS, REVIEW } from '../../util/consts'

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  fill: #b5d443;
`

const StyledUncheckedCircleIcon = styled(RadioButtonUncheckedIcon)`
  fill: #b5d443;
`

const StyledTabs = styled(Tabs)`
  border-top: 3px solid #b5d443;
  border-bottom: 3px solid #b5d443;
  padding: 8px;
`

const StyledTab = styled(Tab)`
&& {
  color: black !important;
  font-weight: ${props => (props.$active ? '900' : '500')};
  letter-spacing: 1.5px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: ${props => props.$dense ? '50px' : 'unset'};
  margin-left: ${props => props.$dense ? '15px' : '35px'};
  margin-right: ${props => props.$dense ? '15px' : '35px'};
}
`

const useStyles = makeStyles(theme => ({
  tabsRoot: {
    minHeight: '56px'
  },
  indicator: {
    backgroundColor: 'white'
  },
  tabWrapper: {
    width: '100px'
  }
}))

const ProgressBarDesktop = ({ sectionName, sections, value, setValue }) => {
  const [state] = useContext(SectionContext)
  const [additionalSteps] = useContext(StepsContext)
  const classes = useStyles()
  const { t } = useTranslation()

  const sectionCompleteLogo = section => {
    if (state[section]?.completed) {
      return <StyledCheckCircleIcon data-testid='icon-checked' />
    } else {
      return <StyledUncheckedCircleIcon data-testid='icon-unchecked' />
    }
  }

  return (
    <StyledTabs
      textColor='primary'
      value={value}
      onChange={(e, newValue) => setValue(newValue)}
      classes={{
        indicator: classes.indicator,
        root: classes.tabsRoot
      }}
      centered
    >
      {sections.map(section => (
        <StyledTab
          $active={section.name === sectionName ? 1 : 0}
          icon={sectionCompleteLogo(section.name)}
          key={section.name + 'tab'}
          value={section.name}
          label={section.title}
          classes={{
            wrapper: classes.tabWrapper
          }}
          $dense={sections.length >= 6}
          disabled
        />
      ))}
      <StyledTab
        $active={sectionName === REVIEW ? 1 : 0}
        icon={sectionCompleteLogo(REVIEW)}
        key='review-tab'
        value={REVIEW}
        label={t('review')}
        $dense={sections.length >= 6}
        disabled
      />
      {Object.keys(additionalSteps).length > 0 && (
        <StyledTab
          $active={sectionName === ADDITIONAL_STEPS ? 1 : 0}
          icon={sectionCompleteLogo(ADDITIONAL_STEPS)}
          key='steps-tab'
          value={ADDITIONAL_STEPS}
          label={t('additional-steps')}
          classes={{
            wrapper: classes.tabWrapper
          }}
          $dense={sections.length >= 6}
          disabled
        />
      )}
    </StyledTabs>
  )
}

ProgressBarDesktop.propTypes = {
  sectionName: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ),
  setValue: PropTypes.func,
  value: PropTypes.string
}

export default ProgressBarDesktop
