// PACKAGES
import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { createContext } from 'use-context-selector'

export const SectionContext = createContext()

export const SectionProvider = ({ children, initialState = {} }) => {
  const reducer = (state, action) => {
    return { ...state, [action.id]: action.value }
  }

  const value = useReducer(reducer, initialState)

  return (
    <SectionContext.Provider value={value}>
      {children}
    </SectionContext.Provider>
  )
}

SectionProvider.propTypes = {
  children: PropTypes.any,
  initialState: PropTypes.object
}
