// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
import { useContext } from 'use-context-selector'
import styled from 'styled-components'
// UI
import { Typography, Grid } from '@material-ui/core'
// CONTEXTS
import { AnswerContext } from '../../contexts/AnswerContext'

const AnswerLabel = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`

const PreferenceAnswer = ({ question: { id, options, numberOfPreferences } }) => {
  const [answerContext] = useContext(AnswerContext)
  const noAnswer = '--'

  const getAnswerLabel = (preferenceNo) => {
    const questionId = id + preferenceNo
    const selected = options.find(op => op.value === answerContext[questionId]?.value)
    return selected
      ? selected.label
      : noAnswer
  }

  return (
    <Grid container spacing={1}>
      {Array(numberOfPreferences).fill(1).map((x, preferenceNo) => {
        preferenceNo++
        return (
          <Grid key={preferenceNo} item xs={6}>
            <AnswerLabel>
              Preference {preferenceNo}: {getAnswerLabel(preferenceNo)}
            </AnswerLabel>
          </Grid>
        )
      })}
    </Grid>
  )
}

PreferenceAnswer.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired
}

export default PreferenceAnswer
