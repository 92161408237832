// SECTION NAMES
export const CONTACT_INFO = 'contact-information'
export const EDUCATION = 'education'
export const GENERAL_QS = 'general-questions'
export const REVIEW = 'review'
export const SCHEDULE = 'schedule'
export const SUCCESS = 'success'
export const ADDITIONAL_STEPS = 'additional-steps'

// RESUME ENUM STATES
export const HIDDEN = 'hidden'
export const OPTIONAL = 'optional'
export const REQUIRED = 'required'

// JWT
export const ARETE_TOKEN = 'use_arete'
export const COGNITO_ID_TOKEN = 'cognito-id-token'

// FILE UPLOAD
export const OWNER_TYPE_APPLICANT = 'applicant'
export const OWNER_TYPE_APPLICATION = 'application'

// PIXEL TRACKING STAGES
export const TRACKING_START = 'start'
export const TRACKING_SUBMIT = 'submit'
export const TRACKING_FINISH = 'finish'

// ADDITIONAL STEPS
export const WOTC = 'wotc'
export const STANDARD_FORMS = 'standard-forms'
export const ASSESSMENT = 'assessment'
export const HIRE_UP = 'hire-up'
export const EEOC = 'eeoc'
export const APPLY = 'apply'
