// PACKAGES
import React, { memo, forwardRef, useCallback } from 'react'
import PropTypes from 'prop-types'
// MODELS
import Answer from '../../models/Answer'
// COMPONENTS
import AutoCompleteQuestion from './AutoCompleteQuestion'
import DateQuestion from './DateQuestion'
import DropdownQuestion from './DropdownQuestion'
import HierarchicalPrimaryQuestion from './HierarchicalPrimaryQuestion'
import HierarchicalSecondaryQuestion from './HierarchicalSecondaryQuestion'
import HierarchicalQuestion from './HierarchicalQuestion'
import InformationQuestion from './InformationQuestion'
import MultiSelectQuestion from './MultiSelectQuestion'
import PreferenceQuestion from './PreferenceQuestion'
import SelectQuestion from './SelectQuestion'
import TextAreaQuestion from './TextAreaQuestion'
import TextQuestion from './TextQuestion'
import UnitQuestion from './UnitQuestion'
import YesNoQuestion from './YesNoQuestion'

const Question = memo(forwardRef(({ answer, dispatch, question }, ref) => {
  const { id, parentId, type } = question

  const handleChange = useCallback(({ target: { value } }) => {
    dispatch({ id, answer: new Answer(value, '') })
  }, [dispatch, id])

  const handleCheckAnswer = useCallback(({ target: { checked } }) => {
    const value = checked ? '1' : '0'
    dispatch({ id, answer: new Answer(value, '') })
  }, [dispatch, id])

  const handleMultipleChange = useCallback((values) => {
    dispatch({ id, answer: new Answer('', '', values) })
  }, [dispatch, id])

  const handleChangeById = useCallback((id, value) => {
    dispatch({ id, answer: new Answer(value, '') })
  }, [dispatch])

  const { errorMessage, value, values } = answer || new Answer('', '')

  const baseProps = {
    errorMessage,
    onChange: handleChange,
    question,
    value
  }

  const questionMap = {
    autocomplete: {
      QuestionComponent: AutoCompleteQuestion,
      extraProps: { dispatch }
    },
    date: {
      QuestionComponent: DateQuestion
    },
    hierarchical: {
      QuestionComponent: HierarchicalQuestion
    },
    'hierarchical-primary': {
      QuestionComponent: HierarchicalPrimaryQuestion
    },
    'hierarchical-secondary': {
      QuestionComponent: HierarchicalSecondaryQuestion,
      extraProps: { parentId }
    },
    multiselect: {
      QuestionComponent: MultiSelectQuestion,
      extraProps: { handleMultipleChange, onChange: () => {}, selected: values }
    },
    preference: {
      QuestionComponent: PreferenceQuestion,
      extraProps: { handleChangeById }
    },
    select: {
      QuestionComponent: SelectQuestion
    },
    text: {
      QuestionComponent: TextQuestion,
      extraProps: { InputLabelProps: { shrink: value !== '' } }
    },
    textarea: {
      QuestionComponent: TextAreaQuestion
    },
    unit: {
      QuestionComponent: UnitQuestion,
      extraProps: { handleChangeById }
    },
    dropdown: {
      QuestionComponent: DropdownQuestion
    },
    'yes-no': {
      QuestionComponent: YesNoQuestion,
      extraProps: { handleCheckAnswer }
    },
    information: {
      QuestionComponent: InformationQuestion
    }
  }

  const questionType = id === 'address-street' ? 'autocomplete' : type
  const { QuestionComponent, extraProps } = questionMap[questionType]

  const props = extraProps ? { ...baseProps, ...extraProps } : baseProps

  return (
    <div ref={ref}>
      <QuestionComponent {...props} />
    </div>
  )
}))

Question.propTypes = {
  answer: PropTypes.shape({
    errorMessage: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    values: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ])
    )
  }),
  dispatch: PropTypes.func.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    parentId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    type: PropTypes.string.isRequired
  }).isRequired
}

Question.propTypes = {
  answer: PropTypes.shape({
    errorMessage: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    values: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ])
    )
  }),
  dispatch: PropTypes.func.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    parentId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    type: PropTypes.string.isRequired
  }).isRequired
}

export default Question
