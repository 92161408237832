import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import LanguageIcon from '@material-ui/icons/Language'
import InputLabel from '@material-ui/core/InputLabel'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
const LangFormControl = styled(FormControl)`
  && {
    min-width: 135px;

    .MuiInputLabel-root {
        transform: translate(5px, 18px) scale(0.75);
    }

    .MuiSvgIcon-root {
        color: #000000;
        font-size: 30px;
    }

    .MuiSelect-root {
        color: #00000;
    }

    .MuiSelect-select {
        padding: 18.5px 35px;
    }

  }
`
const LanguageSelectDark = () => {
  const { i18n } = useTranslation()
  return (
    <LangFormControl variant='outlined'>
      <InputLabel id='language-label'>
        <LanguageIcon />
      </InputLabel>
      <Select
        labelId='language-label'
        value={i18n.language}
        displayEmpty
        onChange={e => (i18n.changeLanguage(e.target.value))}
        data-testid='language-select'
      >
        <MenuItem value='en'>English</MenuItem>
        <MenuItem value='es'>Español</MenuItem>
        <MenuItem value='fr'>Français</MenuItem>
      </Select>
    </LangFormControl>
  )
}
export default LanguageSelectDark
