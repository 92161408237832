// PACKAGES
import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { createContext } from 'use-context-selector'

export const StepsContext = createContext()

export const StepsProvider = ({ children, initialState = {} }) => {
  const reducer = (state, action) => {
    return { ...state, [action.id]: action.value }
  }

  const value = useReducer(reducer, initialState)

  return (
    <StepsContext.Provider value={value}>
      {children}
    </StepsContext.Provider>
  )
}

StepsProvider.propTypes = {
  children: PropTypes.any,
  initialState: PropTypes.object
}
