import React from 'react'
import { Button, Card, Grid, Typography } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import AdditionalStepCard from './AdditionalStepCard'

const SuccessMessage = styled(Typography)`
  padding-top: 2vh;
`

const AdditionalSteps = styled.div`
  padding-top: 1rem;
`

const Message = styled(Typography)`
  padding: 1rem;
  line-height: 24px;
`

const AlertExclamation = styled(ErrorOutlineIcon)`
  padding-top: 1rem;
  color: #b00020;
  font-size: xx-large;
`

const CreateAccountButton = styled(Button)`
&& {
  margin: 1rem;
  margin-bottom: 2rem;
}
`

const CompleteLaterMessage = styled(Typography)`
&& {
  width:70%;
  margin-left:15%;
  margin-bottom:20px;
  opacity: 60%;
}
`

const ApplicationIncompleteCard = ({ clientName, nextSteps, onComplete }) => {
  const { t } = useTranslation()
  return (
    <Grid item xs={12} data-testid='aic-grid' sx={{ textAlign: 'center' }}>
      <Card raised>
        <SuccessMessage variant='h6' color='primary'>
          {t('application-complete-welcome') + ' '}{clientName || 'TalentReef'}
        </SuccessMessage>
        <AlertExclamation />
        <Message variant='h6'>
          <div>
            {t('application-incomplete-message', { clientName })}
          </div>
          <div>{t('application-incomplete-steps')}</div>
        </Message>
        <AdditionalSteps>
          {nextSteps.map(step => (
            <AdditionalStepCard
              key={step.key}
              label={step.label}
              description={step.description}
              time={step.timeEstimate}
            />
          ))}
        </AdditionalSteps>
        <CreateAccountButton
          color='primary'
          variant='contained'
          type='submit'
          onClick={() => onComplete()}
        >
          {t('view-application-status')}
        </CreateAccountButton>
        <CompleteLaterMessage>{t('complete-application-later')}</CompleteLaterMessage>
      </Card>
    </Grid>
  )
}

export default ApplicationIncompleteCard
