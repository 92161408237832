import { CircularProgress, Grid, Typography, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const WoctRedirect = () => {
  const { t } = useTranslation()

  const WotcMessage = styled(Typography)(() => ({
    fontFamily: 'Inter',
    lineHeight: '1rem'
  }))

  return (
    <Typography variant='h6' component='div'>
      <Grid item xs={12} style={{ display: 'inline-flex', marginBottom: '0.75rem', marginLeft: '5rem', justifyContent: 'center' }}>
        <CircularProgress style={{ width: '2.5rem', height: '2.5rem', color: '#0D1D38' }} />
      </Grid>
      <Grid item>
        <WotcMessage variant='subtitle1' style={{ textAlign: 'center' }}>
          {t('wotc-redirect-message')}
        </WotcMessage>
      </Grid>
    </Typography>
  )
}

export default WoctRedirect
