// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
// UI
import { FormControl, TextField } from '@material-ui/core'

export default function DateQuestion ({
  errorMessage,
  onChange,
  question: {
    format,
    id,
    limit,
    question,
    required
  },
  value,
  ...args
}) {
  const inputProps = { maxLength: limit }
  const inputLabelProps = { shrink: true }

  const handleChange = e => {
    e.target.value.split('-')[0].length <= 4 && onChange(e)
  }

  return (
    <FormControl fullWidth component='fieldset' margin='normal'>
      <TextField
        {...args}
        error={errorMessage.length > 0}
        format={format}
        fullWidth
        helperText={errorMessage}
        id={id}
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
        label={question}
        name={id}
        onChange={handleChange}
        placeholder=''
        required={required}
        size='small'
        type='date'
        value={value}
        variant='outlined'
      />
    </FormControl>
  )
}

DateQuestion.propTypes = {
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.shape({
    format: PropTypes.string,
    id: PropTypes.string.isRequired,
    limit: PropTypes.number,
    question: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired
  }).isRequired,
  value: PropTypes.string
}
