import React from 'react'
import { Grid, Card, CardContent, Typography } from '@material-ui/core'
import styled from 'styled-components'

const AdditionalStep = styled(Card)`
&& {
    width: 280px;
    height: 100px;
    align-content: center;
}
`

const SecondaryFont = styled(Typography)`
&& {
    opacity: 60%;
}
`

const GridCenter = styled(Grid)`
&& {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
}
`

const AdditionalStepCard = ({ label, description, time }) => {
  return (
    <GridCenter>
      <AdditionalStep>
        <CardContent>
          <Typography variant='h5' component='div'>
            {label}
          </Typography>
          <Typography>
            {description}
          </Typography>
          <SecondaryFont>
            {time}
          </SecondaryFont>
        </CardContent>
      </AdditionalStep>
    </GridCenter>
  )
}

export default AdditionalStepCard
