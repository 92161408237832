import React from 'react'
import MaskedInput from 'react-text-mask'

const PhoneTextField = ({ inputRef, ...props }) => {
  return (
    <MaskedInput
      data-testid='masked-phone'
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      showMask
      {...props}
    />
  )
}

export default PhoneTextField
