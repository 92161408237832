// PACKAGES
import React, { useCallback, useState, useEffect } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useContext } from 'use-context-selector'
import queryString from 'query-string'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
// CONFIG
import { brandAssetURL, cognitoIdentityPoolId, pinpointProjectId } from '../config'
// SERVICES
import AmplifyService from '../services/amplify'
import BrandingService from '../http/branding'
import PostingService from '../services/postings'
import ProfileService from '../services/profile'
import SectionService from '../services/sections'
// UTILS
import { ADDITIONAL_STEPS, CONTACT_INFO, OPTIONAL, REQUIRED, REVIEW, SUCCESS } from '../util/consts'
import device from '../util/device'
import { alertUser } from '../util/util'
// CONTEXTS
import { AnswerContext } from '../contexts/AnswerContext'
import { AppContext } from '../contexts/AppContext'
// COMPONENTS
import MultiSection from './Sections/MultiSection'
import PostHeader from './Layout/PostHeader'
import ProgressBarDesktop from './ProgressBar/ProgressBarDesktop'
import ProgressBarMobile from './ProgressBar/ProgressBarMobile'
import Review from './Review/Review'
import Section from './Sections/Section'
import Success from './Messaging/Success'
import AlreadyAppliedDialog from './Messaging/AlreadyAppliedDialog'
import AdditionalStepsProvider from './AdditionalSteps/AdditionalStepsProvider'

const CenterContainer = styled.div`
  width:80vw;

  margin: 0px 138px 50px 138px;

  @media ${device.tablets} {
    margin: 0px 20px 50px 20px;
  }
`

const DesktopContainer = styled.div`
  min-height: 70px;
  width: 100vw;
  position: relative;
  margin: 0 0 50px -50vw;
  left: 50%;

  @media ${device.tablets} {
    display: none;
  }
`

const MobileContainer = styled.div`
  margin: 0 -20px 0 -20px;

  @media ${device.desktops} {
    display: none;
  }
`

const Application = () => {
  const { i18n } = useTranslation()

  const history = useHistory()
  const location = useLocation()
  const { postingId, sectionName } = useParams()

  const [answerContext, answerDispatch] = useContext(AnswerContext)
  const [appContext, appDispatch] = useContext(AppContext)

  const [sections, setSections] = useState([])
  const [value, setValue] = useState(sectionName)
  const [postingDetails, setPostingDetails] = useState()
  const [branding, setBranding] = useState()
  const [showErrorDialog, setShowErrorDialog] = useState(false)

  const loadProfile = useCallback(async (postingData) => {
    const userProfile = await ProfileService.getProfile(appDispatch)
    await ProfileService.loadProfile(userProfile, answerContext, answerDispatch)
  }, [answerDispatch]) // eslint-disable-line

  useEffect(() => {
    (async () => {
      const posting = await PostingService.getPosting(postingId)
      const {
        brand: brandName,
        brandId,
        clientId,
        clientName,
        department,
        jobId,
        location,
        positionId,
        title: positionName,
        workflow
      } = posting

      setPostingDetails(posting)

      // to be consumed by `useTrackingIntegration` hook
      appDispatch({ id: 'trackingParams', value: { clientId, jobId } })

      await loadProfile(posting)

      try {
        const brand = await BrandingService.get(
          postingId,
          clientId,
          brandId
        )

        const faviconElement = document.getElementById('favicon')
        if (faviconElement && brand.data.favicon) {
          faviconElement.href = `${brandAssetURL}/${clientId}/${brand.data.favicon.fileId}`
        }

        setBranding(brand.data)
      } catch (error) {
        console.log('Error fetching brand information', error)
      }

      // allow a query params of `resume_show` & `resume_require` to force the resume upload section to render in optional or required states for QA purposes
      const forceShowResume = queryString.parse(window.location.search).resume_show
      const forceRequireResume = queryString.parse(window.location.search).resume_require
      const resumePayload = workflow?.resume === REQUIRED || forceRequireResume
        ? { show: true, require: true }
        : workflow?.resume === OPTIONAL || forceShowResume
          ? { show: true, require: false }
          : { show: false }
      appDispatch({ id: 'resumeSection', value: resumePayload })

      AmplifyService.initialize(cognitoIdentityPoolId, pinpointProjectId)

      const utmSource = queryString.parse(window.location.search).utm_source || ''
      const utmCampaign = queryString.parse(window.location.search).utm_campaign || ''
      const utmMedium = queryString.parse(window.location.search).utm_medium || ''
      const utmContent = queryString.parse(window.location.search).utm_content || ''
      const utmTerm = queryString.parse(window.location.search).utm_term || ''

      const applicationChannel = 'TalentReef Apply'

      AmplifyService.sendEvent({
        name: 'Application Started',
        attributes: {
          brandId,
          brandName,
          clientId,
          clientName,
          departmentId: department?.id,
          departmentName: department?.name,
          locationId: location?.id,
          locationName: location?.name,
          locationNumber: location?.number,
          positionId,
          positionName,
          postingId,
          utmCampaign,
          utmContent,
          utmMedium,
          utmSource,
          utmTerm,
          applicationChannel
        }
      })

      PostingService.getQuestions(
        postingId,
        data => {
          setSections(data)
        })

      i18n.on('languageChanged', lng => {
        PostingService.getQuestions(
          postingId,
          data => {
            setSections(data)
          })
      })
    })()

    return () => {
      i18n.off('languageChanged')
    }
  }, [appDispatch, i18n, loadProfile, postingId])

  useEffect(() => {
    setValue(sectionName)
  }, [sectionName])

  useEffect(() => {
    if (value === SUCCESS || value === ADDITIONAL_STEPS) {
      window.removeEventListener('beforeunload', alertUser)
    } else {
      window.addEventListener('beforeunload', alertUser)
    }
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [value])

  // If state is blank and not on the first section go back to beginning
  const firstSection = sections[0]
  if (
    Object.entries(answerContext).length === 0 &&
    firstSection &&
    window.location.pathname !==
      `/postings/${postingId}/application/${firstSection.name}`
  ) {
    history.replace(`/postings/${postingId}/application/${firstSection.name}`)
  }

  const checkIfUserApplied = async state => {
    const firstName = state['profile-firstName'].value
    const lastName = state['profile-lastName'].value
    const email = state['profile-email'].value
    const phone = state['profile-phone'].value
    const hasApplied = await ProfileService.checkUserApplied(firstName, lastName, phone, email, postingId)
    return hasApplied
  }

  const onMoveNextSection = async completedSectionId => {
    if (completedSectionId === CONTACT_INFO) {
      const applied = await checkIfUserApplied(answerContext)

      if (applied) {
        setShowErrorDialog(true)
        return
      }
    }

    const nextSection = SectionService.getNextSection(sections, sectionName)
    if (nextSection) {
      history.push(`/postings/${postingId}/application/${nextSection}${location.search}`)
    } else {
      history.push(`/postings/${postingId}/application/review${location.search}`)
    }
  }

  const renderSection = section => {
    if (section.name === value) {
      return section.sectionType === 'multiple'
        ? <MultiSection key={section.name + '-section'} section={section} onMoveNextSection={onMoveNextSection} />
        : (
          <Section
            key={section.name + '-section'}
            onMoveNextSection={onMoveNextSection}
            resumeSection={appContext.resumeSection}
            section={section}
          />
          )
    }
  }

  return (
    <>
      {value !== SUCCESS && <PostHeader postingDetails={postingDetails} branding={branding} />}
      <CenterContainer data-testid='app-container'>
        {!!sections.length && value !== SUCCESS && (
          <>
            <DesktopContainer>
              <ProgressBarDesktop
                sectionName={sectionName}
                sections={sections}
                value={value}
                setValue={setValue}
              />
            </DesktopContainer>
            <MobileContainer>
              <ProgressBarMobile
                sectionName={sectionName}
                sections={sections}
                value={value}
                setValue={setValue}
              />
            </MobileContainer>
          </>
        )}

        {sections.map(renderSection)}

        {sections.length > 0 && value === REVIEW && (
          <Review
            sections={sections}
            editSection={sectionName =>
              history.push(`/postings/${postingId}/application/${sectionName}${location.search}`)}
            postingId={postingId}
            postingDetails={postingDetails}
          />
        )}

        {sections.length > 0 && value === SUCCESS && (
          <Success
            postingId={postingId}
            postingDetails={postingDetails}
          />
        )}

        {sections.length > 0 && value === ADDITIONAL_STEPS && (
          <AdditionalStepsProvider
            postingDetails={postingDetails}
          />
        )}
      </CenterContainer>
      <AlreadyAppliedDialog open={showErrorDialog} closeDialog={() => setShowErrorDialog(false)} postingDetails={postingDetails} />
    </>
  )
}

export default Application
