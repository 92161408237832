// PACKAGES
import { useTranslation } from 'react-i18next'
// UI
import { Grid, Typography, styled } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'

const IFrameContainer = styled(Grid)(({ theme }) => ({
  borderRadius: '0.25rem',
  width: '100%',
  height: '44.0625rem',
  border: '1px solid #E0E0E0',
  marginBottom: '1.81rem',

  [theme.breakpoints.down('xs')]: {
    height: '37.9375rem',
    marginBottom: '1.5rem',
    marginTop: '0.87rem'
  }
}))

const WotcMessage = styled(Typography)(() => ({
  fontFamily: 'Inter',
  lineHeight: '1.5rem'
}))

const WotcAssessmentError = (props) => {
  const { t } = useTranslation()

  return (
    <IFrameContainer container justifyContent='center' alignContent='center'>
      <Grid item xs={12} style={{ display: 'inline-flex', marginBottom: '0.75rem', justifyContent: 'center' }}>
        <ErrorOutline style={{ width: '2.5rem', height: '2.5rem', color: '#DC2626' }} />
      </Grid>
      <Grid item>
        <WotcMessage variant='subtitle1' style={{ textAlign: 'center' }}>
          {t('wotc-error-message-1', { seconds: props.timeRemaining })}<br />
          {t('wotc-error-message-2')}
          <a href='https://mitratech.my.site.com/applicanthelp/s/' target='_blank' style={{ color: '#2A5DB7', textDecoration: 'none' }} rel='noreferrer'>
            {t('wotc-error-message-3')}
          </a>
        </WotcMessage>
      </Grid>
    </IFrameContainer>
  )
}

export default WotcAssessmentError
