import axios from 'axios'
import i18next from 'i18next'

const httpClient = {
  get: async (url, token, headers) => {
    headers = { 'Accept-Language': i18next.language, ...headers }
    if (token) {
      return axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            ...headers
          }
        })
        .then(response => response)
    }
    return axios
      .get(url, { headers })
      .then(response => response)
  },
  post: async (url, data, token, headers) => {
    headers = { 'Accept-Language': i18next.language, ...headers }
    if (token) {
      return axios
        .post(url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            ...headers
          }
        })
    }
    return axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          ...headers
        }
      })
  }
}

export default httpClient
