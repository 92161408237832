export const breakpoints = {
  xs: 0,
  sm: 768,
  md: 960,
  lg: 1280,
  xl: 1920
}

export const device = {
  tablets: `only screen and (max-width: ${breakpoints.sm - 1}px)`,
  desktops: `only screen and (min-width: ${breakpoints.sm}px)`
}

export default device
