// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
// UI
import styled from 'styled-components'

const AnswerLabel = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`

const HierarchicalAnswer = ({ question, answer }) => {
  const noAnswer = '--'

  const getHierarquicalLabel = question => {
    let answerElement
    question.hierarchicalOptions.some(el => {
      answerElement = el.options.find(opt => opt.value === answer)
      return answerElement
    })
    return answerElement ? answerElement.label : noAnswer
  }

  return <AnswerLabel>{getHierarquicalLabel(question)}</AnswerLabel>
}

HierarchicalAnswer.propTypes = {
  answer: PropTypes.string.isRequired,
  question: PropTypes.shape({
    hierarchicalOptions: PropTypes.arrayOf(
      PropTypes.shape({
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
          })
        )
      })
    )
  }).isRequired
}

export default HierarchicalAnswer
