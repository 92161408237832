// PACKAGES
import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import styled from 'styled-components'
// UI
import { createTheme, MuiThemeProvider, CircularProgress } from '@material-ui/core'
// CONFIG
import routes from './routes'
import { environment, imagesURL, mouseflowApiKey } from './config'
// UTILS
import { breakpoints } from './util/device'
// CONTEXTS
import { AppProvider } from './contexts/AppContext'

const CenteredProgress = styled(CircularProgress)`
&& {
  position: absolute;
  top: 50%;
  left: 50%;
}
`

const history = createBrowserHistory()

export default class App extends React.Component {
  constructor (props) {
    super(props)

    // dynamically add env-specific global favicon url to html-mounted element
    const faviconElement = document.getElementById('favicon')
    if (faviconElement) {
      faviconElement.href = imagesURL + '/icon/favicon-133x133.png'
    }

    // if not developing locally, dynamically append mouseflow script tag with env-specific api key
    if (environment !== 'local') {
      window._mfq = window._mfq || [];
      (() => {
        const mf = document.createElement('script')
        mf.defer = true
        mf.type = 'text/javascript'
        mf.src = `//cdn.mouseflow.com/projects/${mouseflowApiKey}.js`
        document.getElementsByTagName('head')[0].appendChild(mf)
      })()
    }

    this.state = {
      authState: null,
      user: null
    }
  }

  render () {
    const theme = createTheme({
      breakpoints: {
        values: breakpoints
      }
    })

    return (
      <AppProvider>
        <MuiThemeProvider theme={theme}>
          <Suspense fallback={<CenteredProgress />}>
            <Router history={history}>
              <div>
                {routes.map(route => {
                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      exact={route.exact}
                      component={props => {
                        if (route.layout) {
                          return (
                            <route.layout {...props}>
                              <route.component {...props} />
                            </route.layout>
                          )
                        } else {
                          return <route.component {...props} />
                        }
                      }}
                    />
                  )
                })}
              </div>
            </Router>
          </Suspense>
        </MuiThemeProvider>
      </AppProvider>
    )
  }
}
