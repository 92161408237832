// PACKAGES
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'
import { useContext } from 'use-context-selector'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import qs from 'query-string'
// UI
import {
  CardHeader,
  CardContent,
  Card,
  CircularProgress,
  Button,
  Grid,
  Typography
} from '@material-ui/core'
// COMPONENTS
import AlreadyAppliedDialog from '../Messaging/AlreadyAppliedDialog'
import Answer from '../Answers/Answer'
import ConfirmResponses from './ConfirmResponses'
import ErrorDialog from './ErrorDialog'
import { FilesList } from '../FileUploader/FileUploader'
import PageHeaderTypography from '../Styled/PageHeaderTypography'
// CONTEXTS
import { AnswerContext } from '../../contexts/AnswerContext'
import { AppContext } from '../../contexts/AppContext'
import { FileContext } from '../../contexts/FileContext'
import { SectionContext } from '../../contexts/SectionContext'
// SERVICES
import ProfileService from '../../services/profile'
import UploadAnswers from '../../http/uploadAnswers'
import UploadFile from '../../http/uploadFile'
import AnswersService from '../../services/answers'
// HOOKS
import { useMountEffect } from '../../util/hooks'
// UTILS
import { CONTACT_INFO, REVIEW } from '../../util/consts'
import { getFirstParam } from '../../util/util'

const HeaderTypography = styled(Typography)`
  height: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
`

const SpacedCard = styled(Card)`
  margin-bottom: 40px;
`

const SectionHeader = styled(CardHeader)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`

const Footer = styled.div`
  width: 100%;
  text-align: right;
  margin-right: 10px;
  margin-bottom: 40px;
`

const MultiSectionHeader = styled(Typography)`
&& {
  font-weight: 500;
  font-size: 20px;
  color: #404040;
}
`

const FlexBox = styled.div`
  display: flex;
  justify-content: flex-end;
`

const FlexItem = styled.div`
  margin: theme.spacing(1);
  position: relative;
`

const Progress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

const Review = ({ sections, editSection, postingId, postingDetails }) => {
  const [answerContext] = useContext(AnswerContext)
  const [appContext] = useContext(AppContext)
  const [fileContext] = useContext(FileContext)
  const [sectionContext, sectionDispatch] = useContext(SectionContext)

  const [showErrorDialog, setShowErrorDialog] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showAlreadyAppliedDialog, setShowAlreadyAppliedDialog] = useState(false)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const { search } = useLocation()

  const queryParams = qs.parse(search)

  const hasResume = appContext.resumeSection?.show && !!Object.keys(fileContext).length

  const submitApplication = async () => {
    const jsonAnswerBundle = AnswersService.getApplication(sections, postingDetails, answerContext, sectionContext, getFirstParam(queryParams.applicant_guid), getFirstParam(queryParams.utm_source))
    jsonAnswerBundle.applicant.isAuthenticated = !!appContext.user

    try {
      const hasApplied = await ProfileService.checkUserApplied(jsonAnswerBundle.applicant.firstName, jsonAnswerBundle.applicant.lastName, jsonAnswerBundle.applicant.phoneNumber, jsonAnswerBundle.applicant.email, postingId)

      if (hasApplied) {
        setShowAlreadyAppliedDialog(true)
        return
      }
    } catch (e) {
      console.warn('Error checking if user had already applied', e)
    }

    try {
      setLoading(true)

      if (hasResume) {
        // Attempt to upload all files in parallel, erroring if ANY fail
        await Promise.all(Object.values(fileContext).map(async file => {
          await UploadFile.post({ applicationId: jsonAnswerBundle.id, profileId: appContext.profileId }, file, 1)
        }))
      }

      const resp = await UploadAnswers.post(jsonAnswerBundle)

      sectionDispatch({ id: REVIEW, value: { completed: true } })

      const redirectQueryString = (search ? `${search}&` : '?') + `token=${resp.data.token}`
      history.push(`/postings/${postingId}/application/success${redirectQueryString}`)
    } catch (e) {
      if (e?.response?.status === 413) {
        setErrorMessage(t('error-document-too-big'))
      } else {
        setErrorMessage(t('error-try-again'))
      }
      setShowErrorDialog(true)
      setLoading(false)
    }
  }

  const renderAnswers = ({ questions }, addlId = '') =>
    questions.reduce((acc, q) => {
      // additional id used for multisection answer ids
      const id = `${q.id}${addlId}`
      answerContext[id] &&
      // blank CONDITIONAL questions should NOT be rendered,
      // while blank OPTIONAL questions should be rendered
      (!q.condition || answerContext[id]?.value !== '--') &&
      acc.push(
        <Grid key={id} item xs={12}>
          <Answer data-testid='review-answer' question={q} answer={answerContext[id]} />
        </Grid>
      )
      return acc
    }, [])

  const renderMultiSectionAnswers = section => {
    if (answerContext[section.name + '-question'] && answerContext[section.name + '-question'].value === '0') {
      return (
        <Grid item xs={12}>
          <MultiSectionHeader>--</MultiSectionHeader>
        </Grid>
      )
    }

    return Array(sectionContext[section.name]?.multiplicity || []).fill().map((_, idx) => {
      return (
        <React.Fragment key={section.name + (idx + 1)}>
          <Grid item xs={12}>
            <MultiSectionHeader>{`${section.description} #${idx + 1}`}</MultiSectionHeader>
          </Grid>
          {renderAnswers(section, idx + 1)}
        </React.Fragment>
      )
    })
  }

  useMountEffect(() => { window.scrollTo(0, 0) })

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageHeaderTypography variant='h6'>
            {t('review-and-submit-header')}
          </PageHeaderTypography>
        </Grid>
        {sections.map(section => (
          <React.Fragment key={section.name}>
            <Grid key={section.name} item xs={12}>
              <SpacedCard>
                <SectionHeader
                  title={(
                    <HeaderTypography>
                      {section.title}
                    </HeaderTypography>
                  )}
                  action={(
                    <Button
                      color='primary'
                      onClick={() => editSection(section.name)}
                    >
                      {t('edit-button')}
                    </Button>
                  )}
                />
                <CardContent>
                  {section.sectionType === 'multiple'
                    ? renderMultiSectionAnswers(section)
                    : renderAnswers(section)}
                </CardContent>
              </SpacedCard>
            </Grid>
            {(section.name === CONTACT_INFO) && hasResume && (
              <Grid data-testid='review-resume-section' item xs={12}>
                <SpacedCard>
                  <SectionHeader
                    title={(
                      <HeaderTypography>
                        {t('resume-header')}
                      </HeaderTypography>
                    )}
                    action={(
                      <Button
                        color='primary'
                        onClick={() => editSection(CONTACT_INFO)}
                      >
                        {t('edit-button')}
                      </Button>
                    )}
                  />
                  <CardContent>
                    <FilesList ctx={fileContext} />
                  </CardContent>
                </SpacedCard>
              </Grid>
            )}
          </React.Fragment>
        ))}
        <Grid item xs={12}>
          <ConfirmResponses postingId={postingId} />
        </Grid>
      </Grid>
      <ErrorDialog open={showErrorDialog} message={errorMessage} closeDialog={() => setShowErrorDialog(false)} />
      <AlreadyAppliedDialog open={showAlreadyAppliedDialog} closeDialog={() => setShowAlreadyAppliedDialog(false)} postingDetails={postingDetails} />
      <Footer>
        <FlexBox>
          <FlexItem>
            <Button
              variant='contained'
              color='primary'
              disabled={loading}
              onClick={() => submitApplication()}
            >
              {t('submit-application-button')}
            </Button>
            {loading && <Progress size={24} />}
          </FlexItem>
        </FlexBox>
      </Footer>
    </div>
  )
}

Review.propTypes = {
  editSection: PropTypes.func,
  postingDetails: PropTypes.shape({
    clientName: PropTypes.any,
    jobId: PropTypes.any,
    location: PropTypes.shape({
      name: PropTypes.any
    }),
    title: PropTypes.any,
    url: PropTypes.any
  }),
  postingId: PropTypes.any,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  )
}

export default Review
