import Postings from '../http/postings'
import Jwt from './jwt'

export default class PostingsService {
  static async getPosting (postingId) {
    const idToken = Jwt.getIdToken()
    const response = await Postings.getPosting(postingId, idToken)
    return response.data
  }

  static getQuestions (postingId, callback) {
    const idToken = Jwt.getIdToken()
    Postings.getQuestions(postingId, idToken).then(res => {
      callback(res.data)
    })
  }

  static getConfirmationText (postingId) {
    const idToken = Jwt.getIdToken()
    return Postings.getDisclosure(postingId, idToken)
  }

  static async getAdditionalSteps (clientId, locationId, postingId) {
    const idToken = Jwt.getIdToken()
    const response = await Postings.getAdditionalSteps(
      clientId,
      locationId,
      postingId,
      idToken
    )
    return response.data
  }

  static parseQuestions (questions) {
    const allQuestionIds = []
    const sectionState = {}
    questions.forEach(section => {
      section.questions.map(question => allQuestionIds.push(question.id))
      sectionState[section.name] = { completed: false }
    })

    return {
      questions,
      sectionState,
      allQuestionIds
    }
  }
}
