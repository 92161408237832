import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Jwt from '../../services/jwt'
import Message from './Message'
import CircularProgress from '@material-ui/core/CircularProgress'

const ProfileAction = props => {
  useEffect(() => {
    const idToken = Jwt.getIdToken()
    Jwt.userProfile(idToken)
      .then(userProfile => {
        props
          .actionMethod(userProfile, idToken)
          .then(() => {
            props.onComplete()
          })
          .catch(() => props.onComplete())
      })
      .catch(() => props.onComplete())
  })

  return (
    <Grid container justifyContent='center'>
      <Message>We are {props.action} your profile.</Message>
      <CircularProgress />
    </Grid>
  )
}

export default ProfileAction
