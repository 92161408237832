// PACKAGES
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useContext } from 'use-context-selector'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'query-string'
// UI
import { Box, useMediaQuery } from '@material-ui/core'
// SERVICES
import ApplicationsService from '../services/applications'
// UTILS
import device from '../util/device'
// CONTEXTS
import { AppContext } from '../contexts/AppContext'
// COMPONENTS
import ApplicationSelectHeader from './Layout/ApplicationSelectHeader'
import ApplicationsTable from './Sections/ApplicationsTable'
import ApplicationsTableMobile from './Sections/ApplicationsTableMobile'
import Jwt from '../services/jwt'
import { legacyApplyURL } from '../config'
import Redirect from '../services/redirect'

const SignInPromptBox = styled(Box)`
  height: 100%;
  width: 50%;
  margin: auto;
`

const MobileContainer = styled.div`
  width: 100%;
`

const closeStaleSession = async () => {
  Jwt.removeIdToken()
  Jwt.removeAreteToken()
  const legacySignOutPage = `${legacyApplyURL}/apply/logout.cfm?redirect=${window.location.href}`
  Redirect.navigate(legacySignOutPage)
}

const ApplicationTableComponents = ({ applications, token }) => {
  const isMobile = useMediaQuery(device.tablets)
  return isMobile ? <MobileContainer><ApplicationsTableMobile applications={applications} /></MobileContainer> : <ApplicationsTable applications={applications} />
}

const SingleApplicationSelect = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [appState] = useContext(AppContext)
  const [applicationsData, setApplicationsData] = useState({ applicant: { firstName: '', lastName: '' }, applications: [] })
  const [appSelectError, setAppSelectError] = useState(t('applications-sign-up-prompt'))
  const { search } = useLocation()
  const { token } = qs.parse(search)

  useEffect(() => {
    async function fetchApplicationData () {
      try {
        let { applicant, applications } = await ApplicationsService.getApplicationWithToken(token)
        applications = applications.map((application, index) => ({ ...application, id: index }))
        setApplicationsData({ applicant, applications })
      } catch (e) {
        if (token && e?.response?.status === 404) {
          history.push('/applicant/recover-applications')
        } else if (e?.response?.status === 401 || e?.response?.status === 403) {
          closeStaleSession()
        } else {
          setAppSelectError(t('applications-general-error'))
        }
      }
    }
    fetchApplicationData()
  }, [t, history, token, appState.user])

  return (
    <>
      <ApplicationSelectHeader name={`${applicationsData?.applicant?.firstName || ''} ${applicationsData?.applicant?.lastName || ''}`} />
      {((appState.user && (applicationsData?.applicant?.firstName || applicationsData?.applicant?.lastName)) || token)
        ? <ApplicationTableComponents applications={applicationsData?.applications || []} />
        : <SignInPromptBox>{appSelectError}</SignInPromptBox>}
    </>
  )
}

export default SingleApplicationSelect
