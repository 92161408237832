// PACKAGES
import React, { useState } from 'react'
import { Redirect, useParams, useLocation } from 'react-router-dom'
import { useContext } from 'use-context-selector'
import queryString from 'query-string'
import styled from 'styled-components'
// UI
import { Grid } from '@material-ui/core'
// SERVICES
import Postings from '../../services/postings'
import RedirectService from '../../services/redirect'
// HOOKS
import { useMountEffect } from '../../util/hooks'
// CONTEXTS
import { AppContext } from '../../contexts/AppContext'
// COMPONENTS
import Message from '../Messaging/Message'
import RedirectMessage from '../Messaging/RedirectMessage'

const AppGrid = styled(Grid)`
   {
    min-height: 100vh;
    align-items: center;
    justify-content: center;
  }
`

const parseProfileUrl = profileUrl => {
  const lastSlashIndex = profileUrl.lastIndexOf('/')
  const profileRedirectUrl = profileUrl.slice(0, lastSlashIndex + 1)
  return profileRedirectUrl
}

const ApplyRedirect = () => {
  const [actionStatus, setActionStatus] = useState('fetching')
  const [forwardParams, setForwardParams] = useState('')
  const [, appDispatch] = useContext(AppContext)
  const { postingId } = useParams()
  const queryParams = queryString.parse(useLocation().search)

  useMountEffect(() => {
    const getPosting = async () => {
      try {
        const posting = await Postings.getPosting(postingId)

        appDispatch({ id: 'clientId', value: posting.clientId })
        appDispatch({ id: 'postingId', value: postingId })

        if (posting.isTrApplyExperience) {
          // read query params from redirect url and make sure we forward to legacy apply
          if (queryParams.redirect) {
            setForwardParams(RedirectService.getQueryParams(queryParams.redirect))
          }
          setActionStatus('redirect-arete')
        } else {
          setActionStatus('redirect-legacy')
        }
      } catch {
        setActionStatus('redirect-legacy')
      }
    }

    if (queryParams.redirect) {
      const profileRedirectUrl = parseProfileUrl(queryParams.redirect)
      appDispatch({
        id: 'profileRedirectUrl',
        value: profileRedirectUrl
      })
    }

    getPosting()
  })

  return (
    <AppGrid container>
      <Grid item xs={3}>
        {actionStatus === 'fetching' && (
          <Message>Fetching application...</Message>
        )}

        {actionStatus === 'redirect-legacy' && <RedirectMessage />}

        {actionStatus === 'redirect-arete' && (
          <Redirect to={`/postings/${postingId}/application${forwardParams}`} />
        )}
      </Grid>
    </AppGrid>
  )
}

export default ApplyRedirect
