import jwt_decode from 'jwt-decode' // eslint-disable-line
import Profile from '../http/profile'
import Jwt from './jwt'
import Answer from '../models/Answer'

export default class ProfileService {
  static async getProfile (appDispatch) {
    const idToken = Jwt.getIdToken()
    if (idToken) {
      const jwt = jwt_decode(idToken)
      const cognitoId = jwt['cognito:username']
      const response = await Profile.get(cognitoId, idToken)
      if (response?.data) {
        appDispatch({ id: 'profileId', value: response.data.profileId })
        return response.data
      }
    }
  }

  static async checkUserApplied (firstName, lastName, phone, email, jobId) {
    const response = await Profile.checkUserApplied(
      firstName,
      lastName,
      phone,
      email,
      jobId
    )
    return response?.data
  }

  static async loadProfile (profileState, answerState, answerDispatch) {
    // set answers from Profile but allow overriding with local state
    const setAnswerFromProfile = (profileStateField, answerStateField) => {
      if (profileState[profileStateField] != null && answerState[answerStateField] == null) {
        answerDispatch({
          id: answerStateField,
          answer: new Answer(profileState[profileStateField], '')
        })
      }
    }

    if (profileState != null) {
      setAnswerFromProfile('firstName', 'profile-firstName')
      setAnswerFromProfile('lastName', 'profile-lastName')
      setAnswerFromProfile('email', 'profile-email')
      setAnswerFromProfile('phone', 'profile-phone')
      setAnswerFromProfile('street', 'address-street')
      setAnswerFromProfile('street2', 'address-street2')
      setAnswerFromProfile('city', 'address-city')
      setAnswerFromProfile('country', 'address-countryState')
      if (profileState.country != null && profileState.country === 'US') {
        setAnswerFromProfile('state', 'address-countryState_US')
      } else if (profileState.country != null && profileState.country === 'CA') {
        setAnswerFromProfile('state', 'address-countryState_CA')
      }
    }
  }

  static parseApplyUrl (postingUrl) {
    const postingArr = postingUrl.split('/')
    postingArr.pop()
    return postingArr.join('/')
  }
}
