// PACKAGES
import parsePhoneNumber from 'libphonenumber-js'
import { v4 as uuidv4 } from 'uuid'
// SERVICES
import Validation from './validation'

export default class AnswersService {
  static getApplication (sections, postingDetails, answerContext, sectionContext, applicantGuid, source) {
    const applicant = {}
    const address = {}
    const answers = []

    sections.map(section => {
      if (section.sectionType === 'multiple') {
        const multipleSectionQuestions = []
        for (const question of section.questions) {
          for (let idx = 1; idx <= sectionContext[section.name].multiplicity; idx++) {
            multipleSectionQuestions.push({ ...question, id: question.id + idx, parentId: question.parentId ? question.parentId + idx : undefined })
          }
        }
        multipleSectionQuestions.forEach(q => this.getAnswer(applicant, address, q, answerContext, answers))
      } else {
        section.questions.forEach(q => this.getAnswer(applicant, address, q, answerContext, answers))
      }
      return ''
    })

    const job = {
      jobId: postingDetails.jobId,
      jobTitle: postingDetails.title,
      jobCompany: postingDetails.clientName,
      jobLocation: postingDetails.location.name,
      jobUrl: postingDetails.url
    }

    return {
      id: uuidv4(),
      job,
      applicant,
      address,
      answers,
      applicantGuid,
      source
    }
  }

  static getAnswer (applicant, address, question, answerContext, answers) {
    if (
      !answerContext[question.id] &&
      question.condition?.id &&
      answerContext[question.condition.id] !== question.condition.value
    ) {
      // missing answer is CONDITIONAL, do not force placeholder
    } else {
      const answer = answerContext[question.id]?.value || '' // missing answer is OPTIONAL and needs placeholder
      switch (question.id) {
        case 'profile-firstName':
          applicant.firstName = answer
          break
        case 'profile-lastName':
          applicant.lastName = answer
          break
        case 'profile-phone':
          applicant.phoneNumber = parsePhoneNumber(answer, 'US').nationalNumber
          break
        case 'profile-email':
          applicant.email = answer
          break
        case 'address-street':
          address.street = answer
          break
        case 'address-street2':
          address.street2 = answer
          break
        case 'address-city':
          address.city = answer
          break
        case 'address-country':
          address.country = answer
          break
        case 'address-state':
          address.state = answer
          break
        case 'address-zip':
          address.zip = Validation.parseZip(answer)
          break
        default: {
          const normalAnswer = {
            id: question.id,
            value: answer
          }
          switch (question.type) {
            case 'multiselect':
              answers.push({
                id: question.id,
                values: answerContext[question.id].values
              })
              return
            case 'unit':
              answers.push({
                id: question.id,
                values: [
                  answerContext[question.unitQuestion.id]?.value || '',
                  answer
                ]
              })
              return
            case 'preference':
              for (let preferenceNo = 1; preferenceNo <= question.numberOfPreferences; preferenceNo++) {
                const questionId = question.id + preferenceNo
                answers.push({
                  id: questionId,
                  value: answerContext[questionId]?.value || ''
                })
              }
              return
            case 'hierarchical-primary':
              // do nothing
              return
            case 'hierarchical-secondary':
              answers.push({
                id: question.id,
                values: [answerContext[question.parentId]?.value, answer]
              })
              return
            default:
              if (question.validation === 'phone-number' && answer !== '') {
                normalAnswer.value = parsePhoneNumber(answer, 'US').nationalNumber
              }
              answers.push(normalAnswer)
          }
        }
      }
    }
  }
}
