// PACKAGES
import React from 'react'
import PropTypes from 'prop-types'
// UI
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup
} from '@material-ui/core'
// COMPONENTS
import QuestionFormLabel from '../Styled/QuestionFormLabel'

export default function SelectQuestion ({
  errorMessage,
  question: {
    id,
    options,
    question,
    required
  },
  ...extra
}) {
  const isError = !!errorMessage.length
  return (
    <FormControl component='fieldset' margin='normal' error={isError}>
      <QuestionFormLabel required={required}>
        <span dangerouslySetInnerHTML={{ __html: question }} />
      </QuestionFormLabel>
      <RadioGroup
        {...extra}
        id={id}
        name={id}
      >
        {options.map(opt => {
          const optionLabel = options.length > 1 ? opt.label : 'I agree'
          return (
            <FormControlLabel
              key={opt.value} value={opt.value}
              control={<Radio color='primary' />} label={optionLabel}
            />
          )
        })}
      </RadioGroup>
      {isError && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

SelectQuestion.propTypes = {
  errorMessage: PropTypes.string,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string
        ]),
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string
        ])
      })
    ).isRequired,
    question: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired
  }).isRequired
}
