// PACKAGES
import React from 'react'
import MaskedInput from 'react-text-mask'

const ZipPostalCodeTextField = ({ inputRef, ...props }) => {
  const letterOrDigit = /[a-zA-Z0-9]/
  const spaceOrDigit = /[0-9 ]/
  const digit = /[0-9]/

  return (
    <MaskedInput
      data-testid='masked-zip'
      mask={[
        letterOrDigit,
        digit,
        letterOrDigit,
        spaceOrDigit,
        letterOrDigit,
        letterOrDigit,
        digit
      ]}
      placeholderChar=' '
      guide={false}
      {...props}
    />
  )
}

export default ZipPostalCodeTextField
