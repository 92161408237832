import http from './httpClient'
import { bffURL } from '../config'

export default class Postings {
  static getQuestions (postingId, idToken) {
    return http.get(`${bffURL}/postings/${postingId}/questions`, idToken).catch(e => (console.warn(`Failed to fetch questions: ${e}`)))
  }

  static getPosting (postingId, idToken) {
    return http.get(`${bffURL}/postings/${postingId}`, idToken).catch(e => (console.warn(`Failed to fetch posting: ${e}`)))
  }

  static getAdditionalSteps (clientId, locationId, postingId, idToken) {
    return http.get(
      `${bffURL}/clients/${clientId}/locations/${locationId}/postings/${postingId}/steps`,
      idToken
    ).catch(e => (console.warn(`Failed to fetch additional steps: ${e}`)))
  }

  static getDisclosure (postingId, idToken) {
    return http.get(`${bffURL}/postings/${postingId}/disclosure`, idToken).catch(e => (console.warn(`Failed to fetch disclosure: ${e}`)))
  }
}
