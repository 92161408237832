// PACKAGES
import PropTypes from 'prop-types'
import React from 'react'
import queryString from 'query-string'
import { useContext } from 'use-context-selector'
import { useTranslation } from 'react-i18next'
// UI
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'
// CONTEXTS
import { AppContext } from '../../contexts/AppContext'
// CONFIG
import { applicantURL, authURL, legacyApplyURL } from '../../config'

function AlreadyAppliedDialog ({ closeDialog, open, postingDetails }) {
  const [appState] = useContext(AppContext)
  const { t } = useTranslation()

  let myApplicationsLink = ''
  let signInRedirect = ''
  if (postingDetails) {
    const postingPath = postingDetails.url.split('/')
    myApplicationsLink = `${legacyApplyURL}/${postingPath[1]}/${postingPath[2]}`
    signInRedirect = queryString.stringifyUrl({
      url: `${authURL}/sign-in`,
      query: {
        redirect: [applicantURL, myApplicationsLink]
      }
    })
  }

  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>
          {t('already-applied')}
        </DialogContentText>
        <DialogContentText style={{ textAlign: 'center' }}>
          <a href={appState.user ? myApplicationsLink : signInRedirect}>{t('my-applications')}</a>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={closeDialog} color='primary'>
          {t('close-button')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AlreadyAppliedDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  postingDetails: PropTypes.shape({
    url: PropTypes.string
  })
}

export default AlreadyAppliedDialog
