// PACKAGES
import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { createContext } from 'use-context-selector'

export const FileContext = createContext()

export const FileProvider = ({ children, initialState = {} }) => {
  const reducer = (state, action) => {
    if (action.file === undefined && state[action.id]) {
      // passing undefined as a `file` value should allow the reducer to delete a field
      const newState = { ...state }
      delete newState[action.id]
      return newState
    }
    return { ...state, [action.id]: action.file }
  }

  const value = useReducer(reducer, initialState)

  return (
    <FileContext.Provider value={value}>
      {children}
    </FileContext.Provider>
  )
}

FileProvider.propTypes = {
  children: PropTypes.any,
  initialState: PropTypes.object
}
